import { Grid, useMediaQuery } from "@material-ui/core"
import { UploadItemTitle } from "./UploadItemTitle";
import { UploadItemFormCard } from "./UploadItemFormCard";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { UploadSummaryButtons } from "./UploadSummaryButtons";
import { LocalStorageProvider } from "../../../providers/LocalStorageProvider";

export const UploadItemHome = () => {
    const history = useHistory();
    const { t } = useTranslation(['LibOfThings']);
    const isMobile = useMediaQuery('(max-width:600px)');
    const [buttonsShown, setButtonsShown] = useState(false);

    const showButtons = () => {
        setButtonsShown(true);
    }

    return (
        <LocalStorageProvider>
            <Grid container spacing={4} style={{ marginBottom: '10em' }}>
                <Grid item xs={12} onClick={() => history.push('/')} style={{
                    cursor: 'pointer'
                }}>
                    <UploadItemTitle text={t('registerNewItem')} />
                </Grid>
                <Grid item xs={12} style={{
                    margin: '16px',
                }}>
                    <UploadItemFormCard showButtons={showButtons} buttonsShown={buttonsShown} />
                </Grid>
                <UploadSummaryButtons buttonsShown={buttonsShown} />
            </Grid>
        </LocalStorageProvider>

    )
}