import { useMediaQuery } from "@material-ui/core";
import { Preorder, PreorderStatuses } from "../../../../types/calendar.type";

import Owner_Desktop_Received_1 from "../../../../../src/assets/img/steppers/metto-in-prestito-1.svg";
import Owner_Desktop_Confirmed_2 from "../../../../../src/assets/img/steppers/metto-in-prestito-2.svg";
import Owner_Desktop_Started_3 from "../../../../../src/assets/img/steppers/metto-in-prestito-3.svg";
import Owner_Desktop_Completed_4 from "../../../../../src/assets/img/steppers/metto-in-prestito-4.svg";
import Owner_Desktop_Denied_5 from "../../../../../src/assets/img/steppers/metto-in-prestito-5.svg";

import Lender_Desktop_Received_1 from "../../../../../src/assets/img/steppers/chiedo-in-prestito-1.png";
import Lender_Desktop_Confirmed_2 from "../../../../../src/assets/img/steppers/chiedo-in-prestito-2.png";
import Lender_Desktop_Started_3 from "../../../../../src/assets/img/steppers/chiedo-in-prestito-3.png";
import Lender_Desktop_Completed_4 from "../../../../../src/assets/img/steppers/chiedo-in-prestito-4.png";
import Lender_Desktop_Denied_5 from "../../../../../src/assets/img/steppers/chiedo-in-prestito-5.png";

import Mobile_Confirmed_2 from "../../../../../src/assets/img/steppers/mobile/metto-in-prestito-2.png";
import Mobile_Received_1 from "../../../../../src/assets/img/steppers/mobile/metto-in-prestito-1.png";
import Mobile_Started_3 from "../../../../../src/assets/img/steppers/mobile/metto-in-prestito-3.png";
import Mobile_Completed_4 from "../../../../../src/assets/img/steppers/mobile/metto-in-prestito-4.png";
import Mobile_Denied_5 from "../../../../../src/assets/img/steppers/mobile/metto-in-prestito-5.png";

export interface IPreorderStepperProps {
    preorder: Preorder,
    lender: boolean
}

const ownerDesktopStatusSrc = new Map<string, string>([
    [PreorderStatuses.Pending.toUpperCase(), Owner_Desktop_Received_1],
    [PreorderStatuses.Accepted.toUpperCase(), Owner_Desktop_Confirmed_2],
    [PreorderStatuses.Started.toUpperCase(), Owner_Desktop_Started_3],
    [PreorderStatuses.Ended.toUpperCase(), Owner_Desktop_Completed_4],
    [PreorderStatuses.Denied.toUpperCase(), Owner_Desktop_Denied_5]
]);

const lenderDesktopStatusSrc = new Map<string, string>([
    [PreorderStatuses.Pending.toUpperCase(), Lender_Desktop_Received_1],
    [PreorderStatuses.Accepted.toUpperCase(), Lender_Desktop_Confirmed_2],
    [PreorderStatuses.Started.toUpperCase(), Lender_Desktop_Started_3],
    [PreorderStatuses.Ended.toUpperCase(), Lender_Desktop_Completed_4],
    [PreorderStatuses.Denied.toUpperCase(), Lender_Desktop_Denied_5]
]);

const mobileStatusSrc = new Map<string, string>([
    [PreorderStatuses.Pending.toUpperCase(), Mobile_Received_1],
    [PreorderStatuses.Accepted.toUpperCase(), Mobile_Confirmed_2],
    [PreorderStatuses.Started.toUpperCase(), Mobile_Started_3],
    [PreorderStatuses.Ended.toUpperCase(), Mobile_Completed_4],
    [PreorderStatuses.Denied.toUpperCase(), Mobile_Denied_5]
])

const PreorderStepper: React.FC<IPreorderStepperProps> = (props) => {
    const useMobileQuery = useMediaQuery('(max-width: 600px)');

    //Both owner & lender bars in mobile layout are identical
    if (useMobileQuery) {
        return (
            <img width={'80%'} src={mobileStatusSrc.get(props.preorder.status)}></img>
        )
    }
    if (props.lender)
        return (
            <img width={'100%'} src={lenderDesktopStatusSrc.get(props.preorder.status)}></img>
        );
    else
        return (
            <img width={'100%'} src={ownerDesktopStatusSrc.get(props.preorder.status)}></img>
        );
}

export default PreorderStepper;