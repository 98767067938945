import { useContext } from "react";
import { LocalStorageContext } from "../../../providers/LocalStorageProvider";
import { Box, Grid, Typography } from "@material-ui/core";
import { ItemInfoInForm, ItemInfoSavedInLS } from "../../../types/items.type";
import { base64ToFile, getImageFromBase64String } from "../../../utilities/localstorage/getBase64Image";
import { FormikProps } from "formik";
import _ from "lodash";

const ListOfItemsInLocalStorage = ({ formik }: { formik: FormikProps<ItemInfoInForm> }) => {
  const { itemsSavedInLS, updateLocalStorageItems } = useContext(LocalStorageContext);

  const onClickImage = (item: ItemInfoSavedInLS, index: number) => {
    const imgElement = document.getElementById(`image/${index}`) as HTMLImageElement;
    let file;
    if (imgElement) {
      const imgSrc = imgElement.src;
      const fileName = `${item.name}.png`;
      const mimeType = item.mime || 'image/png';
      file = base64ToFile(imgSrc, fileName, mimeType);
    }
    formik.setFieldValue('name', item.name);
    formik.setFieldValue('category', item.category);
    formik.setFieldValue('requireSupervision', item.requireSupervision === 'true');
    formik.setFieldValue('image', file);

    removeFromListOfSavedItems(item);
  };

  const removeFromListOfSavedItems = (item: ItemInfoSavedInLS) => {
    const itemsInLS = localStorage.getItem('savedItems');
    let idxToRemove = 0;
    if (itemsInLS) {
      const itemsConverted = JSON.parse(itemsInLS);
      for (const i of itemsConverted) {
        if (_.isEqual(i, item)) {
          break;
        }
        idxToRemove++;
      }
      itemsConverted.splice(idxToRemove, 1);
      updateLocalStorageItems('savedItems', JSON.stringify(itemsConverted));
    }
  };

  return (
    <Box marginTop={'20px'} position={'relative'}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5">Bozze salvate</Typography>
        </Grid>
        <Grid item xs={12}>
          <Box
            display="flex"
            flexDirection="row"
            style={{ overflowX: "auto", whiteSpace: "nowrap" }}
          >
            {itemsSavedInLS.length > 0 && JSON.parse(itemsSavedInLS).map(
              (item: ItemInfoSavedInLS, index: number) => {
                return (
                  <img
                    onClick={() => onClickImage(item, index)}
                    id={`image/${index}`}
                    key={index}
                    src={getImageFromBase64String(item.imageBase64, item.mime)}
                    alt={item.name}
                    style={{ maxWidth: "200px", margin: "10px", borderRadius: "10%" }}
                  />
                );
              }
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ListOfItemsInLocalStorage;
