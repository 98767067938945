import { Box, Button, Grid, Typography, makeStyles, useMediaQuery } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useContext, useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getItemByID } from "../../../store/slices/libOfThingsSlice";
import { ItemInformation } from "./ItemInformation";
import { getDatesOfItem } from "../../../store/slices/calendarSlice";
import { Item, ItemStats } from "../../../types/libofthings.type";
import { getItemStats } from "../../../api/libOfThingsAPI";
import { CalendarContractContext } from "../../../providers/CalendarContractProvider";
import toast from "react-hot-toast";
import { CustomToaster } from "../../Notifications/CustomToaster";
import { useTranslation } from "react-i18next";
import { UploadItemTitle } from "../UploadItem/UploadItemTitle";
import { getTokenFromSymbolSlice } from "../../../store/slices/coinSlice";
import { getTokenWithBalanceFromMetadata } from "../../../api/coinAPI";
import { Token } from "../../../types/coin.type";
import { TutorialFab } from "../Tutorial/TutorialFab";
import { TutorialContext } from "../../../providers/TutorialProvider";
import { MailContext } from "../../../providers/MailProvider";
import { FirstLifeContextImpl } from "../../../providers/FirstLifeProvider";

type ParamsOfPage = {
    collection: string,
    itemID: string
}

const classes = makeStyles({
    button: {
        border: '1px solid rgba(0, 0, 0, 1)',
        borderRadius: '15px',
        color: 'white',
        textTransform: 'none',
        width: '20%',
        '&:enabled': {
            background: 'black',
            color: 'white'
        },
        '&:hover': {
            background: 'rgba(235, 176, 76, 1)',
            color: 'black',
            border: 'none'
        },
        '&:focus': {
            background: 'rgba(218, 93, 62, 1)',
            color: 'black'
        },
        '@media (max-width:600px)': {
            width: '90%',
        }
    },
})

export const ItemPage = (props: any) => {
    const params = useParams<ParamsOfPage>();
    const dispatch = useAppDispatch();
    const [item, setItem] = useState<Item>();
    const [itemStats, setItemStats] = useState<ItemStats>();
    const [loading, setLoading] = useState<boolean>(false);
    const profile = useAppSelector(state => state.user.currentProfile)
    const [summaryOpened, setSummaryOpened] = useState<boolean>(false);
    const { handlePreorder, startDate, endDate } = useContext(CalendarContractContext);
    const history = useHistory();
    const useClasses = classes();
    const { t } = useTranslation('LibOfThings');
    const [erc20Info, setErc20Info] = useState<Token>();
    const isMobile = useMediaQuery('(max-width:600px)');
    const { openTutorialDesktop, openTutorialMobile } = useContext(TutorialContext);
    const { sendEmailWrapper } = useContext(MailContext);
    const { getUserByAddress } = useContext(FirstLifeContextImpl);
    const [emailLender, setemailLender] = useState<string>('');
    const [name, setName] = useState<string>('');

    useEffect(() => {
        if (item != null) {
            (async () => await dispatch(getDatesOfItem(item.itemID)))()
            getItemStats(item.itemID.toString())
                .then(stats => {
                    setItemStats(stats);
                })
                .catch(err => {
                    setItemStats(undefined);
                    console.log(err);
                })
        }
    }, [item]);

    useEffect(() => {
        if (item == null || item.itemID.toString() !== params.itemID) {
            (async () => {
                const item = await dispatch(getItemByID(parseInt(params.itemID)))
                setItem(item);
            }
            )();
        }
    }, [])

    useEffect(() => {
        (async () => {
            const tokenAddr = await dispatch(getTokenFromSymbolSlice('COSO'));
            if (tokenAddr != null && profile?.additional_properties?.commonshoodWallet != null) {
                const balance = await getTokenWithBalanceFromMetadata(profile?.additional_properties?.commonshoodWallet, tokenAddr.contractAddress, 'COSO');
                setErc20Info(balance);
            }
        })()
    }, [])

    const createPreorderWrapper = async () => {
        await crearePreorder();
    }

    const crearePreorder = async () => {
        const toastIdLoading = toast.loading('');
        setLoading(true);
        if (item == null || endDate == null || startDate == null) return;

        const days = Math.floor((endDate?.getTime() - startDate?.getTime()) / (1000 * 60 * 60 * 24));

        if (days > Number(erc20Info?.balance)) {
            toast.error('Non hai abbastanza COSO');
            setLoading(false);
            return;
        }
        try {
            if (item != null && profile != null && profile?.additional_properties?.commonshoodWallet != null) {
                const trx = await handlePreorder(item);
                const { first_name, email } = await getUserByAddress(profile?.additional_properties?.commonshoodWallet);
                if (trx != null) {
                    setSummaryOpened(true);
                    await sendEmailWrapper(
                        emailLender,
                        'Ci sono delle novità dalla Biblioteca delle cose di COSO',
                        `
                        ${first_name} ti ha inviato una richiesta di prestito
                        <br/><br/>
                        Accedi alla <a href="https://coso-beta.commonshood.eu/">Biblioteca delle cose</a>:
                        <br/>
                        <ul>
                            <li>Trovi la richiesta nella sezione <b>"Metto in prestito - In attesa"</b></li>
                            <li>Fai clcik sul pulsante <b>"Accetta"</b> per confermare la tua disponibilità al prestito</li>
                            <li>Puoi anche rifiutare, cliccando sul pulsante <b>"Rifiuta"</b> se non puoi più prestare l'oggetto</li>
                        </ul>   
                        <br/>
                        <b>Importante!</b> ${first_name} attende di essere contattato/a da te: scrivi al suo indirizzo email ${email}, e organizzate
                        un incontro per la consegna dell'oggetto!
                        <br/><br/>
                        <a href="https://www.progettocoso.org/">https://www.progettocoso.org/</a>
                        <br/>
                        <br/>
                        <img src="https://resources-beta.commonshood.eu/ipfs/QmVd8aDiavT16ufhTGjSTPKbfMGrJSJdRR92Cqx4wjxgc9" alt="COSO" width="100" />
                        `
                    );
                }
            }
        } catch (error: any) {
            toast.error(error.message);
            console.log(error);
        } finally {
            toast.dismiss(toastIdLoading);
        }
    }

    const tutorialFabCallback = () => {
        if (isMobile)
            openTutorialMobile();
        else
            openTutorialDesktop();
    }

    useEffect(() => {
        (async () => {
            if (item != null) {
                const { email, first_name } = await getUserByAddress(item.owner);
                setemailLender(email);
                setName(first_name);
            }
        })()
    }, [item])


    return (
        <>
            <TutorialFab callback={tutorialFabCallback} />

            <CustomToaster duration={999999999}/>
            <Grid container item xs={12} onClick={() => history.push('/')} style={{
                cursor: 'pointer',
                marginTop: '1em'
            }}>
                <UploadItemTitle text={'Chiedo in prestito'} />
            </Grid>
            <Grid container style={{
                paddingLeft: 16
            }}>
                <Grid item xs={12}>
                    {
                        (item == null || itemStats == null) ? (
                            <Skeleton variant="rect" width={'100%'} height={'100%'} />
                        ) : (
                            <ItemInformation item={item} summaryOpened={summaryOpened} email={emailLender} />
                        )
                    }
                </Grid>
                <Box style={{
                    width: '100%',
                    marginTop: '2em',
                    textAlign: 'center',
                    marginBottom: '10em'
                }}>
                    <Grid item xs={12} style={{ marginBottom: '16px' }}>
                        <Typography variant="body2">Inviando la richiesta sarà prelevata la quantità di COSO necessaria per il prestito, se la richiesta verrà rifiutata i COSO saranno restituiti.</Typography>
                    </Grid>
                    <Grid item xs={12} >
                        {
                            item && !loading && !summaryOpened && (profile?.additional_properties?.commonshoodWallet !== item.owner) && (
                                <Button disabled={loading || endDate == null || startDate == null} onClick={createPreorderWrapper} className={useClasses.button} >{t('sendRequest')}</Button>
                            )
                        }
                        {
                            summaryOpened && (
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Button className={useClasses.button}
                                            onClick={() => history.push('/')}
                                        >Torna alla Home</Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button style={{
                                            minWidth: '200px',
                                            color: 'rgba(218, 93, 62, 1)',
                                            textTransform: 'none',
                                            marginBottom: '15em'
                                        }}
                                            onClick={() => history.push('/user/requests')}
                                        >Vai a Chiedo in prestito</Button>
                                    </Grid>
                                </Grid>
                            )
                        }
                    </Grid>
                </Box>
            </Grid>
        </>
    );
}