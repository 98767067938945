import { Grid } from "@material-ui/core"
import { Item } from "../../../types/libofthings.type"
import { ItemCard } from "../Cards/ItemCard/ItemCard"
import { NoItemsDisplay } from "../BasicComponents/NoItemsDisplay"
import { delay } from "../../../utilities/timer/wait"

export const ListOfCards = ({ items, callback }: {
    items: Item[],
    callback?: () => any
}) => {

    const deletionCallback = async () => {
        if (callback)
            await callback();
    }

    return (
        <Grid container spacing={3}>
            {items.length === 0 &&
                <NoItemsDisplay text="Nessun oggetto trovato" />
            }
            {
                items.map((item) => {
                    return <Grid key={item.itemID} item xs={6} sm={4} md={3} lg={2}>
                        <ItemCard
                            item={item}
                            callback={async () => {
                                await delay(1000); deletionCallback()
                            }}
                        />
                    </Grid>
                })
            }
        </Grid>
    )
}