import { Grid } from "@material-ui/core"
import { useAppSelector } from "../../../../../store/hooks"
import { Skeleton } from "@material-ui/lab"
import { useContext, useEffect, useState } from "react";
import { Preorder } from "../../../../../types/calendar.type";
import { PaginationContainer } from "../../../../../components/UI/Pagination/PaginationContainer";
import { getActivePreordersAPI } from "../../../../../api/calendar";
import { ActivePreorderInList } from "./ActivePreorderInList";
import { CustomToaster } from "../../../../Notifications/CustomToaster";
import { CalendarContractContext } from "../../../../../providers/CalendarContractProvider";
import { NoItemsDisplay } from "../../../BasicComponents/NoItemsDisplay";
import toast from "react-hot-toast";
import { delay } from "../../../../../utilities/timer/wait";

export const ListOfActivePreorders = ({ shouldRefresh }: { shouldRefresh: boolean }) => {
    const account = useAppSelector(state => state.user.currentProfile);
    const preordersPerPage = useAppSelector(state => state.calendar.preordersPerPage);
    const loading = useAppSelector(state => state.calendar.loading);
    const [numberOfPreorders, setNumberOfPreorders] = useState<number>(0);
    const [preordersToShow, setPreordersToShow] = useState<Preorder[]>([]);
    const { getUserStats, getPreordersInLastXHours } = useContext(CalendarContractContext);

    useEffect(() => {
        (async () => {
            await getPreordersFromMetadata(0);
            if (account?.additional_properties?.commonshoodWallet) {
                const almostFinishedPreorders = await getPreordersInLastXHours(
                    account?.additional_properties?.commonshoodWallet,
                    true,
                    48
                )
                if (almostFinishedPreorders.length > 0) {
                    toast.error('Il prestito sta per terminare! Contatta via email gli utenti per la riconsegna dell’oggetto.')
                }
            }
        })()
    }, []);

    useEffect(() => {
        (async () => {
            await getPreordersFromMetadata(0);
        })()
    }, [shouldRefresh]);

    const handleClickOnPagination = async (selectedPage: number) => {
        await getPreordersFromMetadata(selectedPage);
    }

    const getPreordersFromMetadata = async (page: number) => {
        console.log(`Refreshing list of preorders`)
        await delay(1000)
        const address = account?.additional_properties?.commonshoodWallet;
        if (address) {
            const activePreorders = await getActivePreordersAPI(
                address,
                true,
                page * preordersPerPage,
                preordersPerPage
            );
            setPreordersToShow(activePreorders.preorders.preorders);
            setNumberOfPreorders(activePreorders.preorders.numberOfPreorders);
        }
    }

    return (
        <>
            <CustomToaster />
            <Grid container style={{ position: 'relative', top: '3em' }} spacing={3}>
                {
                    preordersToShow.length === 0 ? (
                        <NoItemsDisplay style={{ marginTop: '3em' }} />
                    ) : (
                        <>
                            {
                                preordersToShow.map((p) => {
                                    return <Grid style={{ marginBottom: '100px' }} key={p.preorderID} item xs={12}>
                                        <ActivePreorderInList
                                            preorder={p}
                                            callback={async () => {
                                                getPreordersFromMetadata(0);
                                                getUserStats();
                                            }}
                                        />
                                    </Grid>
                                })
                            }
                        </>
                    )
                }

                <Grid item xs={12} style={{ marginBottom: '15em' }} >
                    <PaginationContainer pageCount={Math.ceil(numberOfPreorders / preordersPerPage)} onChange={handleClickOnPagination} />
                </Grid>
            </Grid>
        </>

    )
}