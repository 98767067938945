import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { getTokenFromSymbolSlice } from "../../../store/slices/coinSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getCoinTransactions, getTokenWithBalanceFromMetadata } from "../../../api/coinAPI";
import { Token } from "../../../types/coin.type";
import { format } from 'date-fns';
import { CircularProgress, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, makeStyles } from "@material-ui/core";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import config from "../../../config";
import { AccountAction } from "../../LibOfThings/MyAccount/AccountActions";

interface QueryParams {
    symbol: string
}

export const CouponOverviewPage = () => {
    const params = useParams<QueryParams>();
    const dispatch = useAppDispatch();
    const history = useHistory();
    const [token, setToken] = useState<Token>();
    const user = useAppSelector(state => state.user.currentProfile);


    useEffect(() => {
        const getToken = async () => {
            try {
                const tokenByApi = await dispatch(getTokenFromSymbolSlice(params.symbol));
                if (user === undefined)
                    throw new Error();
                if (user?.additional_properties === undefined)
                    throw new Error();
                if (user.additional_properties.commonshoodWallet === undefined)
                    throw new Error();
                if (tokenByApi === undefined || tokenByApi === null)
                    throw new Error();
                const balance = await getTokenWithBalanceFromMetadata(user?.additional_properties?.commonshoodWallet, tokenByApi.contractAddress, token?.symbol);
                if (balance === undefined || balance === null)
                    throw new Error();
                setToken(balance);
            } catch (error) {
                console.clear();
                console.error('Error fetching exchanges:', error);
            }
        };

        getToken();
    }, []); // Run once on component mount

    if (token === undefined || token === null)
        return <WaitingForApi />;


    const styles = {
        descriptionTitle: {
            color: 'var(--Text-abilitato, #181B1D)',
            fontFamily: '"Be Vietnam Pro", sans-serif',
            fontSize: '24px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: 'normal'
        },
        descriptionText: {
            marginTop: '1rem',
            color: 'var(--Text-abilitato, #181B1D)',
            fontFamily: '"Be Vietnam Pro", sans-serif',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: 'normal'
        },
        balanceDisplay: {
            width: '205px',
            height: '75px',
            flexShrink: 0,
            borderRadius: '15px',
            background: '#FFF'
        }
    };

    return (
        <div style={{ height: '100vh' }}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center' }} onClick={() => history.push('/user/wallet')}>
                <ArrowBackIosIcon style={{ cursor: 'pointer' }} />
                <h1>{token.symbol}</h1>
            </div>

            {/* Logo, Description & Actions */}
            <Grid container direction="row" >
                {/* Logo */}
                <div
                    style={{
                        width: '204px',
                        height: '204px',
                        borderRadius: '50%',
                        overflow: 'hidden',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <img
                        src={token.logo.replace(config.network.ipfs.default_url, config.network.ipfs.gateway_url)}
                        alt="Circular"
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            borderRadius: '50%',
                        }}
                    />
                </div>

                {/* Description */}
                <Grid item style={{ maxWidth: '435px', marginLeft: '2rem' }}>
                    <Typography style={styles.descriptionTitle}>Descrizione</Typography>
                    <Typography style={styles.descriptionText}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam dictum neque et leo molestie, quis scelerisque eros consequat. Vestibulum non consectetur dui. In vel auctor massa, ut pharetra. </Typography>
                </Grid>
                <AccountAction link="/user/wallet?page=coupon" title={"Invia"} style={{
                    marginLeft: '4rem',
                    marginTop: '18px',
                    width: '243px', height: '50px', color: '#181B1D',
                    textAlign: 'center',
                    fontFamily: '"Be Vietnam Pro", sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: 'normal'
                }} />
            </Grid>

            <Grid container direction="row" style={{ marginTop: '1.5rem' }}>
                <Grid container item direction="column" alignItems="center" style={{ width: 'max-content' }}>
                    <Typography style={styles.descriptionTitle}>Bilancio</Typography>
                    <Typography align="center" style={{ ...styles.balanceDisplay, marginTop: '1rem' }}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', ...styles.descriptionTitle }}>
                            {token.balance ? parseFloat(token.balance.toString()).toFixed(token.decimals) : 'N/A'}
                        </div>
                    </Typography>
                </Grid>
                <Grid container item direction="column" xs style={{ marginLeft: '25px' }}>
                    <Typography style={{ ...styles.descriptionTitle, marginBottom: '18px' }}>Storico delle transazioni con {token.symbol.toUpperCase()}</Typography>
                    <TransactionTable tokenAddress={token.address} />
                </Grid>
            </Grid>






            {/* <h1>Balance: {token.balance ? parseFloat(token.balance.toString()).toFixed(token.decimals) : 'N/A'}</h1> */}
        </div>
    )
}



const WaitingForApi = () => (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
        <CircularProgress />
        <Typography variant="body1" style={{ marginTop: '1.25rem' }}>Caricamento</Typography>
    </div>
)


type TransactionDirection = "Inviati" | "Ricevuti";
const TransactionSentIconSrc = "/svgs/coupons/red_sent_transaction.svg";
const TransactionRecvIconSrc = "/svgs/coupons/green_recv_transaction.svg"

//As long as the APIs are implemented with 'any' keep this
interface Transaction {
    id: string;
    body: {
        category: string;
        message: string;
        params: {
            symbol: string;
            decimals: number;
            contract_address: string;
            amount?: number;
            sender?: string;
            receiver?: string;
            to_name?: string;
            from_name?: string;
            to_realm?: string;
            from_realm?: string;
            from?: string; //Only if COIN_CREATED
        };
    };
    delivered: boolean;
    timestamp: number;
    type: string;
}

interface TransactionTableRow {
    activity: TransactionDirection;
    recipient: string;
    amount: number;
    date: Date;
}

const TransactionTable = (props: { tokenAddress: string }) => {
    const { tokenAddress } = props;
    const [rows, setRows] = useState<TransactionTableRow[]>([]);
    const user = useAppSelector(state => state.user.currentProfile);
    const useStyles = makeStyles({
        table: {
            minWidth: 650,
        },
        tableContainter: {
            maxWidth: 1124,
            borderRadius: 15,
        },
        tableHeaderText: {
            color: 'var(--Text-abilitato, #181B1D)',
            fontFamily: '"Be Vietnam Pro", sans-serif',
            fontSize: '22px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: 'normal'
        }
    });


    const extractTransactions = async () => {
        const extractedData: TransactionTableRow[] = [];

        let isOwner = false;

        const transactions = await getCoinTransactions(1, 20, tokenAddress) as Transaction[];
        transactions.forEach(transaction => {
            switch (transaction.body.message) {
                case "COIN_SENT":
                    extractedData.push(createData("Inviati", transaction.body.params.to_name || '', transaction.body.params.amount || 0, new Date(transaction.timestamp * 1000)));
                    break;
                case "COIN_RECEIVED":
                    extractedData.push(createData("Ricevuti", transaction.body.params.from_name || '', transaction.body.params.amount || 0, new Date(transaction.timestamp * 1000)));
                    break;
                case "COIN_MINTED":
                    extractedData.push(createData("Ricevuti", 'Creazione Coupon', transaction.body.params.amount || 0, new Date(transaction.timestamp * 1000)));
                    break;
                case "COIN_CREATED":
                    if (transaction.body.params.from === user?.additional_properties?.commonshoodWallet)
                        isOwner = true;
                    break;
                default:
                    break;
            }
        });
        return isOwner ?
            extractedData :
            extractedData.filter(transaction => transaction.recipient !== 'Creazione Coupon');
    }

    useEffect(() => {
        const getTransactions = async () => {
            try {
                const transactionRows = await extractTransactions();
                setRows(transactionRows);
            } catch (error) {
                console.error('Error fetching coin transactions:', error);
            }
        }

        getTransactions();
    }, []); // Run once on component mount


    function createData(activity: TransactionDirection, recipient: string, amount: number, date: Date) {
        return { activity, recipient, amount, date };
    }


    const classes = useStyles();
    return (
        <TableContainer component={Paper} className={classes.tableContainter}>
            <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.tableHeaderText}>Attivit&agrave;</TableCell>
                        <TableCell className={classes.tableHeaderText}>Da/a</TableCell>
                        <TableCell className={classes.tableHeaderText}>Contenuto</TableCell>
                        <TableCell className={classes.tableHeaderText}>Data</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, index) => (
                        <TableRow key={index}>
                            <TableCell component="th" scope="row" style={{ display: 'flex', alignItems: 'center' }}>
                                <img src={row.activity === 'Inviati' ? TransactionSentIconSrc : TransactionRecvIconSrc} alt="" style={{ marginRight: '1rem' }} />
                                {row.activity}
                            </TableCell>
                            <TableCell>{row.activity === 'Inviati' ? 'a' : 'da'} {row.recipient}</TableCell>
                            <TableCell>{row.amount}</TableCell>
                            <TableCell>{format(row.date, 'dd/MM/yyyy')}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}