import { useContext, useEffect, useState } from "react";
import { ListOfCards } from "../NewArrivals/ListOfCards"
import { Item } from "../../../types/libofthings.type";
import { LibraryOfThingsContractContext } from "../../../providers/LibraryOfThingsContractProvider";
import { Box, Grid } from "@material-ui/core";
import { PaginationContainer } from "../../../components/UI/Pagination/PaginationContainer";
import { useAppSelector } from "../../../store/hooks";
import { MyAccountContainer } from "../MyAccount/MyAccountContainer";
import { NoItemsDisplay } from "../BasicComponents/NoItemsDisplay";
import { delay } from "../../../utilities/timer/wait";

export const ItemsExplorerPage = () => {
    const [items, setItems] = useState<Item[]>([]);
    const { getItemsByName } = useContext(LibraryOfThingsContractContext);
    const preordersPerPage = useAppSelector(state => state.calendar.preordersPerPage);
    const [numberOfPreorders, setNumberOfPreorders] = useState<number>(0);

    useEffect(() => {
        (async () => {
            await getItems();
        })()
    }, [window.location.search]);

    const handleClickOnPagination = async (selectedPage: number) => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        const name = params.get('name');
        if (name)
            await getItemsByName(name, selectedPage);
    }

    const deletionCallback = async () => {
        await getItems();
    }

    const getItems = async () => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        const name = params.get('name');
        if (name == null) return;
        const items = await getItemsByName(name, 0);
        setItems(items);
    }

    return (
        <Box margin={'16px'}>
            <Grid container spacing={4}>
                <Grid item xs={12} style={{
                    marginLeft: '16px'
                }}>
                    <MyAccountContainer />
                </Grid>
                <Grid item xs={12}>
                    <ListOfCards
                        callback={async () => {
                            await delay(1000);
                            deletionCallback()
                        }}
                        items={items}
                    />
                </Grid>
                <Grid item xs={12} >
                    {
                        numberOfPreorders > 0 && (
                            <PaginationContainer pageCount={Math.ceil(numberOfPreorders / preordersPerPage)} onChange={handleClickOnPagination} />
                        )
                    }
                </Grid>
            </Grid>
        </Box>


    )
}