import { Grid } from "@material-ui/core"
import { CustomToaster } from "../../Notifications/CustomToaster"
import { Formik, FormikProps } from "formik"
import { useContext, useEffect, useState } from "react";
import { FirstStep } from "./FirstStep";
import { SecondStep } from "./SecondStep";
import { ThirdStep } from "./ThirdStep";
import { ExchangeFormContext } from "../../../providers/ExchangeFormCreationProvider";
import { FourthStep } from "./FourthStep";
import { FifthStep } from "./FifthStep";
import { useAppDispatch } from "../../../store/hooks";
import { exchangeCreate } from "../../../store/slices/exchangeSlice";
import { getTokenFromMetadata } from "../../../api/coinAPI";
import toast from "react-hot-toast";
import { FinalDialog } from "./FinalDialog";

export type ExchangeInfoInForm = {
    tokensRequestedAmount: number;
    symbolOfCouponRequested: string;
    couponsRequestedAmount: number;
    endDate: Date;
    amountOfExchangesAvailable: number;
    couponSrc: string;
    couponAddress: string;
}

export const ExchangeFormContainer = () => {
    const { step } = useContext(ExchangeFormContext);
    const dispatch = useAppDispatch();
    const [dialogOpen, setDialogOpen] = useState(false);

    const onSubmit = async (values: ExchangeInfoInForm) => {
        try {
            setDialogOpen(true);
            const cosoToken = await getTokenFromMetadata('', 'COSO');
            const endDateConvertedInUnix = values.endDate.getTime();

            await dispatch(exchangeCreate(
                [values.couponAddress],
                [values.couponsRequestedAmount],
                [cosoToken.address],
                [values.tokensRequestedAmount],
                values.amountOfExchangesAvailable,
                endDateConvertedInUnix
            ));
            toast.success('Exchange creato correttamente');
        } catch (e) {
            console.log(e);
            toast.error('Errore durante la creazione dello scambio');
        }
    }

    const closeDialog = () => {
        setDialogOpen(false);
    }

    return (
        <>
            <CustomToaster />
            <Grid container style={{ marginBottom: '20em' }}>
                <Grid item xs={12}>
                    <Formik
                        initialValues={{
                            tokensRequestedAmount: 0,
                            symbolOfCouponRequested: '',
                            couponsRequestedAmount: 0,
                            endDate: new Date(),
                            amountOfExchangesAvailable: 0,
                            couponSrc: '',
                            couponAddress: ''
                        }}
                        onSubmit={onSubmit}
                        validate={(values) => { }}
                    >
                        {(formik: FormikProps<ExchangeInfoInForm>) => (
                            <form onSubmit={formik.handleSubmit} style={{
                                width: '100%',
                                height: '100%',
                                minHeight: '470px',
                            }}>
                                <Grid container xs={12}>
                                    {step === 0 && <FirstStep formik={formik} />}
                                    {step === 1 && <SecondStep formik={formik} />}
                                    {step === 2 && <ThirdStep formik={formik} />}
                                    {step === 3 && <FourthStep formik={formik} />}
                                    {step === 4 && <FifthStep formik={formik} />}
                                    <FinalDialog formik={formik} open={dialogOpen} closeDialog={closeDialog} />
                                </Grid>
                            </form>
                        )}
                    </Formik>
                </Grid>
            </Grid>
        </>
    )
}