import { createSlice, PayloadAction, Dispatch } from "@reduxjs/toolkit"
import { RootState } from "../store";
import { acceptPreorderAPI, cancelPreorderAPI, createPreorder, denyPreorderAPI, endLendingPeriodAPI, endRevisionPeriodAPI, fetchDatesForItem, getAcceptedPreordersAPI, getActivePreordersAPI, getCalendarInstance, getCancelledPreordersAPI, getPendingPreordersAPI, getPreorders, getPreordersWithStatusEnded, getPreordersWithStatusReviewAPI, reimburseTokens, startPreorderAPI } from "../../api/calendar";
import { Item } from "../../types/libofthings.type";
import { Preorder, RangeOfDates } from "../../types/calendar.type";
import { Event } from "ethers";

type InitialStateLibOfThingsSlice = {
    loading: boolean,
    pendingPreorders: Preorder[],
    acceptedPreorders: Preorder[],
    startedPreorders: Preorder[],
    activePreordersOfBorrower: Preorder[],
    preordersUnderReview: Preorder[],
    deniedPreorders: Preorder[],
    cancelledPreorders: Preorder[],
    endedPreorders: Preorder[],
    activePreorders: any[],
    rangeOfDatesForItem: RangeOfDates[],
    error: string | null,
    preordersPerPage: number,
    numberOfStartedPreorders: number,
    numberOfPendingPreorders: number,
    numberOfAcceptedPreorders: number,
    numberOfActivePreorders: number,
    numberOfActivePreordersOfBorrower: number,
    numberOfCancelledPreorders: number,
    numberOfDeniedPreorders: number,
    numberOfEndedPreorders: number,
    numberOfPreordersUnderReview: number,
    isUserPaused: boolean,
}

const initialState: InitialStateLibOfThingsSlice = {
    error: null,
    rangeOfDatesForItem: [],
    loading: false,
    preordersUnderReview: [],
    pendingPreorders: [],
    acceptedPreorders: [],
    activePreordersOfBorrower: [],
    cancelledPreorders: [],
    activePreorders: [],
    endedPreorders: [],
    startedPreorders: [],
    deniedPreorders: [],
    preordersPerPage: 5,
    numberOfPendingPreorders: 0,
    numberOfAcceptedPreorders: 0,
    numberOfActivePreordersOfBorrower: 0,
    numberOfActivePreorders: 0,
    numberOfCancelledPreorders: 0,
    numberOfDeniedPreorders: 0,
    numberOfEndedPreorders: 0,
    numberOfPreordersUnderReview: 0,
    numberOfStartedPreorders: 0,
    isUserPaused: false
}

export const calendarSlice = createSlice({
    name: 'calendar',
    initialState,
    reducers: {
        createPreorderStarted(state) {
            state.loading = true;
        },
        createPreorderFailed(state, action: PayloadAction<{ error: string }>) {
            state.loading = false;
            state.error = action.payload.error;
        },
        createPreorderSuccess(state) {
            state.loading = false;
        },
        fetchDatesForItemSuccess(state, action: PayloadAction<{ dates: RangeOfDates[] }>) {
            state.loading = false;
            state.rangeOfDatesForItem = action.payload.dates;
        },
        fetchDatesForItemFailed(state, action: PayloadAction<{ error: string }>) {
            state.loading = false;
            state.error = action.payload.error;
        },
        fetchDatesForItemStarted(state) {
            state.loading = true;
        },
        getPendingPreordersStarted(state) {
            state.loading = true;
        },
        getPendingPreordersFailed(state, action: PayloadAction<{ error: string }>) {
            state.loading = false;
            state.error = action.payload.error;
        },
        getPendingPreordersSuccess(state, action: PayloadAction<{ pendingPreorders: Preorder[], numberOfPendingPreorders: number }>) {
            state.loading = false;
            state.pendingPreorders = action.payload.pendingPreorders;
            state.numberOfPendingPreorders = action.payload.numberOfPendingPreorders;
        },
        acceptPreorderStarted(state) {
            state.loading = true;
            console.log(`state.loading: ${state.loading}`);
        },
        acceptPreorderFailed(state, action: PayloadAction<{ error: string }>) {
            state.loading = false;
            state.error = action.payload.error;
        },
        acceptPreorderSuccess(state) {
            state.loading = false;
        },
        denyPreorderStarted(state) {
            state.loading = true;
        },
        denyPreorderSuccess(state) {
            state.loading = false;
        },
        denyPreorderFailed(state, action: PayloadAction<{ error: string }>) {
            state.error = action.payload.error;
            state.loading = false;
        },
        getAcceptedPreordersStarted(state) {
            state.loading = true;
        },
        getAcceptedPreordersFailed(state, action: PayloadAction<{ error: string }>) {
            state.loading = false;
            state.error = action.payload.error;
        },
        getAcceptedPreordersSuccess(state, action: PayloadAction<{ acceptedPreorders: Preorder[] }>) {
            state.loading = false;
            state.acceptedPreorders = action.payload.acceptedPreorders;
        },
        cancelPreorderStarted(state) {
            state.loading = true;
        },
        cancelPreorderFailed(state, action: PayloadAction<{ error: string }>) {
            state.loading = false;
            state.error = action.payload.error;
        },
        cancelPreorderSuccess(state) {
            state.loading = false;
        },
        getCancelledPreordersStarted(state) {
            state.loading = true;
        },
        getCancelledPreordersFailed(state, action: PayloadAction<{ error: string }>) {
            state.loading = false;
            state.error = action.payload.error;
        },
        getCancelledPreordersSuccess(state, action: PayloadAction<{ cancelledPreorders: Preorder[], total: number }>) {
            state.loading = false;
            state.numberOfCancelledPreorders = action.payload.total;
            state.cancelledPreorders = action.payload.cancelledPreorders;
        },
        getActivePreordersStarted(state) {
            state.loading = true;
        },
        getActivePreordersFailed(state, action: PayloadAction<{ error: string }>) {
            state.loading = false;
            state.error = action.payload.error;
        },
        getActivePreordersSuccess(state, action: PayloadAction<{ activePreorders: any[], total: number }>) {
            state.activePreorders = action.payload.activePreorders;
            state.numberOfActivePreorders = action.payload.total;
            state.loading = false;
        },
        getActivePreordersOfBorrowerSuccess(state, action: PayloadAction<{ activePreorders: any[], total: number }>) {
            state.loading = false;
            state.activePreordersOfBorrower = action.payload.activePreorders;
            state.numberOfActivePreorders = action.payload.total;
        },
        startPreorderStarted(state) {
            state.loading = true;
        },
        startPreorderSuccess(state) {
            state.loading = false;
        },
        startPreorderFailed(state, action: PayloadAction<{ error: string }>) {
            state.loading = false;
            state.error = action.payload.error;
        },
        endLendingPeriodStarted(state) {
            state.loading = true;
        },
        endLendingPeriodSuccess(state) {
            state.loading = false;
        },
        endLendingPeriodFailed(state, action: PayloadAction<{ error: string }>) {
            state.loading = false;
            state.error = action.payload.error;
        },
        endRevisionPeridoStart(state) {
            state.loading = true;
        },
        endRevisionPeriodSuccess(state) {
            state.loading = false;
        },
        endRevisionPeriodFailed(state, action: PayloadAction<{ error: string }>) {
            state.loading = false;
            state.error = action.payload.error;
        },
        getEndedPreordersStarted(state) {
            state.loading = true;
        },
        getEndedPreordersSuccess(state, action: PayloadAction<{ preorders: Preorder[], total: number }>) {
            state.loading = false;
            state.numberOfEndedPreorders = action.payload.total;
            state.endedPreorders = action.payload.preorders;
        },
        getEndedPreordersFailed(state, action: PayloadAction<{ error: string }>) {
            state.loading = false;
            state.error = action.payload.error;
        },
        getPreordersUnderReviewStarted(state) {
            state.loading = true;
        },
        getPreordersUnderReviewFailed(state, action: PayloadAction<{ error: string }>) {
            state.loading = false;
            state.error = action.payload.error;
        },
        getPreordersUnderReviewSuccess(state, action: PayloadAction<{ preorders: Preorder[], total: number }>) {
            state.loading = false;
            state.numberOfPreordersUnderReview = action.payload.total;
            state.preordersUnderReview = action.payload.preorders;
        },
        getStartedPreordersStarted(state) {
            state.loading = true;
        },
        getStartedPreordersSuccess(state, action: PayloadAction<{ preorders: Preorder[], total: number }>) {
            state.loading = false;
            state.numberOfStartedPreorders = action.payload.total;
            state.startedPreorders = action.payload.preorders;
        },
        getStartedPreordersFailed(state, action: PayloadAction<{ error: string }>) {
            state.loading = false;
            state.error = action.payload.error;
        },
        getDeniedPreordersStarted(state) {
            state.loading = true;
        },
        getDeniedPreordersSuccess(state, action: PayloadAction<{ preorders: Preorder[], total: number }>) {
            state.loading = false;
            state.numberOfDeniedPreorders = action.payload.total;
            state.deniedPreorders = action.payload.preorders;
        },
        getDeniedPreordersFailed(state, action: PayloadAction<{ error: string }>) {
            state.loading = false;
            state.error = action.payload.error;
        },
        getPausedUserFailed(state, action: PayloadAction<{ error: string }>) {
            state.loading = false;
            state.error = action.payload.error;
        },
        getPausedUserStarted(state) {
            state.loading = true;
        },
        getPausedUserEnded(state, action: PayloadAction<{ paused: boolean }>) {
            state.loading = false;
        },
        togglePauseStarted(state) {
            state.loading = true;
        },
        togglePauseEnded(state, action: PayloadAction<{ paused: boolean }>) {
            state.loading = false;
            state.isUserPaused = action.payload.paused;
        }
    }
})

export const {
    togglePauseEnded,
    togglePauseStarted,
    createPreorderFailed,
    createPreorderStarted,
    createPreorderSuccess,
    fetchDatesForItemFailed,
    fetchDatesForItemStarted,
    fetchDatesForItemSuccess,
    getPendingPreordersFailed,
    getPendingPreordersStarted,
    getPendingPreordersSuccess,
    acceptPreorderFailed,
    acceptPreorderStarted,
    acceptPreorderSuccess,
    denyPreorderFailed,
    denyPreorderStarted,
    denyPreorderSuccess,
    getAcceptedPreordersFailed,
    getAcceptedPreordersStarted,
    getAcceptedPreordersSuccess,
    cancelPreorderFailed,
    cancelPreorderStarted,
    cancelPreorderSuccess,
    getCancelledPreordersFailed,
    getCancelledPreordersStarted,
    getCancelledPreordersSuccess,
    getActivePreordersFailed,
    getActivePreordersStarted,
    getActivePreordersSuccess,
    startPreorderFailed,
    startPreorderStarted,
    startPreorderSuccess,
    getActivePreordersOfBorrowerSuccess,
    endLendingPeriodStarted,
    endLendingPeriodFailed,
    endLendingPeriodSuccess,
    endRevisionPeridoStart,
    endRevisionPeriodFailed,
    endRevisionPeriodSuccess,
    getEndedPreordersFailed,
    getEndedPreordersStarted,
    getEndedPreordersSuccess,
    getPreordersUnderReviewFailed,
    getPreordersUnderReviewStarted,
    getPreordersUnderReviewSuccess,
    getStartedPreordersFailed,
    getStartedPreordersStarted,
    getStartedPreordersSuccess,
    getDeniedPreordersFailed,
    getDeniedPreordersStarted,
    getDeniedPreordersSuccess,
    getPausedUserStarted,
    getPausedUserEnded,
    getPausedUserFailed
} = calendarSlice.actions;


export const createPreorderSlice = (
    itemID: number,
    startDate: Date,
    endDate: Date,
    hash: string,
    signature: string,
    coinSymbol: string,
    price: number,
    caution: number
) => {
    return async (dispatch: Dispatch, getState: () => RootState): Promise<{ errors: string[], trx?: any }> => {
        dispatch(createPreorderStarted());
        let trx;
        let errors: string[] = [];
        const startDateUnix = Math.floor(startDate.getTime() / 1000);
        const endDateUnix = Math.floor(endDate.getTime() / 1000);
        const currentProfile = getState().user.currentProfile;
        if (currentProfile == null) {
            dispatch(
                createPreorderFailed({
                    error: 'currentProfile is not defined in createPreorderSlice'
                })
            );
            return { errors: ['Current profile is not defined in createPreorderSlice'] };
        }
        const address = currentProfile.additional_properties?.commonshoodWallet;
        if (address == null) {
            dispatch(
                createPreorderFailed({
                    error: 'address is not defined in createPreorderSlice'
                })
            );
            return { errors: ['Address is not defined'] };
        }
        const ethers = getState().ethers.ethersInstance;
        if (ethers == null) {
            dispatch(
                createPreorderFailed({
                    error: 'wallet is not defined in createPreorderSlice'
                })
            );
            return { errors: ['Wallet is not defined'] };
        }
        try {
            trx = await createPreorder(
                ethers,
                itemID,
                startDateUnix,
                endDateUnix,
                hash,
                signature,
                address,
                coinSymbol,
                price,
                caution
            );
            if (trx != null) {
                const events = trx.events;
                if (events != null && events.filter((ev: Event) => ev.event === "PreorderCreated")[0] != null) { //PreorderCreated
                    dispatch(createPreorderSuccess());
                } else {
                    dispatch(
                        createPreorderFailed({
                            error: 'Something went wrong with the transaction'
                        })
                    );
                }
            }
        } catch (error: any) {
            if (error.message != null) {
                const errorMessage = error.message;
                if (errorMessage.includes("There's no ITEM with the ID you requested.")) {
                    throw new Error("Oggetto non trovato");
                } else if (errorMessage.includes('The ending date must be greater then the starting date')) {
                    throw new Error('Date non valide');
                } else if (errorMessage.includes('The days requested exceeds the maximum number of days available for a preorder')) {
                    throw new Error('Numero di giorni non valido');
                } else if (errorMessage.includes('The collection for this item does not exist. Probably the address of the token factory was not set')) {
                    throw new Error('Collezione non trovata');
                } else if (errorMessage.includes('To call this function you need to set its address first')) {
                    throw new Error('Non puoi chiamare questa funzione')
                } else if (errorMessage.includes('Token factory address not set in calendar.')) {
                    throw new Error('Token Factory non impostata')
                } else if (errorMessage.includes('Hash registry address is not set')) {
                    throw new Error('Hash Registry non impostata');
                } else if (errorMessage.includes('Not enough tokens in your balance')) {
                    throw new Error('Non hai abbastanza token nel tuo wallet');
                } else {
                    console.log(error.message);
                }
                dispatch(endRevisionPeriodFailed(
                    { error: error.message }
                ))
            } else {
                console.log(error);
            }
        }
        return { errors: errors, trx };
    }
}

export const getDatesOfItem = (
    itemID: number
) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        dispatch(fetchDatesForItemStarted());
        const currentProfile = getState().user.currentProfile;
        if (currentProfile == null) {
            dispatch(
                fetchDatesForItemFailed({
                    error: 'currentProfile is not defined in createPreorderSlice'
                })
            );
            return;
        }
        const address = currentProfile.additional_properties?.commonshoodWallet;
        if (address == null) {
            dispatch(
                fetchDatesForItemFailed({
                    error: 'address is not defined in createPreorderSlice'
                })
            );
            return;
        }
        try {
            const dates = await fetchDatesForItem(itemID);
            dispatch(fetchDatesForItemSuccess({ dates: dates }));
        } catch (error: any) {
            console.error(error);
        }
    }
}

export const getPendingPreorders = (
    isLender: boolean,
    page: number
) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        console.log('Dispatching getPendingPreorders');
        dispatch(getPendingPreordersStarted());
        const currentProfile = getState().user.currentProfile;
        if (currentProfile == null) {
            dispatch(
                getPendingPreordersFailed({
                    error: 'currentProfile is not defined in getPendingPreorders'
                })
            );
            return;
        }
        const address = currentProfile.additional_properties?.commonshoodWallet;
        if (address == null) {
            dispatch(
                getPendingPreordersFailed({
                    error: 'address is not defined in getPendingPreorders'
                })
            );
            return;
        }
        try {
            const { preorders, totalPreorders } = await getPendingPreordersAPI(
                address,
                isLender,
                page * getState().calendar.preordersPerPage,
                getState().calendar.preordersPerPage
            );
            dispatch(
                getPendingPreordersSuccess({ pendingPreorders: preorders, numberOfPendingPreorders: totalPreorders })
            );
        } catch (error: any) {
            console.error(error.message);
        }
    }
}

export const getAcceptedPreorders = (isLender: boolean, page: number) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        dispatch(getAcceptedPreordersStarted());
        const currentProfile = getState().user.currentProfile;
        if (currentProfile == null) {
            dispatch(
                getAcceptedPreordersFailed({
                    error: 'currentProfile is not defined in getAcceptedPreorders'
                })
            );
            return;
        }
        const address = currentProfile.additional_properties?.commonshoodWallet;
        if (address == null) {
            dispatch(
                getAcceptedPreordersFailed({
                    error: 'address is not defined in getAcceptedPreorders'
                })
            );
            return;
        }
        try {
            const preorders = await getAcceptedPreordersAPI(
                address,
                isLender,
                page * getState().calendar.preordersPerPage,
                getState().calendar.preordersPerPage
            );
            dispatch(
                getAcceptedPreordersSuccess({ acceptedPreorders: preorders.preorders })
            )
        } catch (error: any) {
            dispatch(getAcceptedPreordersFailed(
                { error: error.message }
            ))
            console.error(error);
        }
    }
}

export const getStartedPreorders = (
    isLender: boolean,
    page: number
) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        dispatch(getStartedPreordersStarted());
        const currentProfile = getState().user.currentProfile;
        if (currentProfile == null) {
            dispatch(
                getStartedPreordersFailed({
                    error: 'currentProfile is not defined in getStartedPreorders'
                })
            );
            return;
        }
        const address = currentProfile.additional_properties?.commonshoodWallet;
        if (address == null) {
            dispatch(
                getStartedPreordersFailed({
                    error: 'address is not defined in getStartedPreorders'
                })
            );
            return;
        }
        try {
            const preorders = await getPreorders(
                address,
                isLender,
                page * getState().calendar.preordersPerPage,
                getState().calendar.preordersPerPage,
                'Started'
            );
            dispatch(
                getStartedPreordersSuccess({ preorders: preorders.preorders, total: preorders.numberOfPreorders })
            )
        } catch (error) {

        }
    }
}

export const acceptPreorderRequest = (
    preorderID: string
) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        dispatch(acceptPreorderStarted());
        let errors: string[] = [];
        const currentProfile = getState().user.currentProfile;
        if (currentProfile == null) {
            dispatch(
                acceptPreorderFailed({
                    error: 'currentProfile is not defined in acceptPreorderRequest'
                })
            );
            return ['Address is not defined'];
        }
        const address = currentProfile.additional_properties?.commonshoodWallet;
        if (address == null) {
            dispatch(
                acceptPreorderFailed({
                    error: 'address is not defined in acceptPreorderRequest'
                })
            );
            return;
        }
        const ethers = getState().ethers.ethersInstance;
        if (ethers == null) {
            dispatch(
                acceptPreorderFailed({
                    error: 'wallet is not defined in acceptPreorderRequest'
                })
            );
            return;
        }
        try {
            await acceptPreorderAPI(ethers, preorderID, address);
            dispatch(acceptPreorderSuccess());
        } catch (error: any) {
            const e = error as Error;
            if (e.message) {
                if (e.message.includes("You can't accept a preorder of an item you don't own.")) {
                    errors.push("You can't accept a preorder of an item you don't own.");
                } else if (e.message.includes("This preorder can't be accepted due to its status. It must be PENDING.")) {
                    errors.push("This preorder can't be accepted due to its status. It must be PENDING.");
                } else {
                    console.log(e.message);
                }
                dispatch(acceptPreorderFailed(
                    { error: error.message }
                ))
            } else {
                console.error(error);
            }
        }
    }
}

export const denyPreorderRequest = (
    preorderID: string
) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        let errors: string[] = [];
        dispatch(denyPreorderStarted());
        const currentProfile = getState().user.currentProfile;
        if (currentProfile == null) {
            dispatch(
                denyPreorderFailed({
                    error: 'currentProfile is not defined in denyPreorderRequest'
                })
            );
            return;
        }
        const address = currentProfile.additional_properties?.commonshoodWallet;
        if (address == null) {
            dispatch(
                denyPreorderFailed({
                    error: 'address is not defined in denyPreorderRequest'
                })
            );
            return;
        }
        const ethers = getState().ethers.ethersInstance;
        if (ethers == null) {
            dispatch(
                denyPreorderFailed({
                    error: 'wallet is not defined in denyPreorderRequest'
                })
            );
            return;
        }
        try {
            await denyPreorderAPI(ethers, preorderID, address, '');
            dispatch(denyPreorderSuccess());
        } catch (error: any) {
            console.log(error.message);
        }
        return errors;
    }
}

export const getCancelledPreorders = (isLender: boolean, nPage: number, first: number) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        dispatch(getCancelledPreordersStarted());
        const currentProfile = getState().user.currentProfile;
        if (currentProfile == null) {
            dispatch(
                getCancelledPreordersFailed({
                    error: 'currentProfile is not defined in getCancelledPreorders'
                })
            );
            return;
        }
        const address = currentProfile.additional_properties?.commonshoodWallet;
        if (address == null) {
            dispatch(
                getCancelledPreordersFailed({
                    error: 'address is not defined in getCancelledPreorders'
                })
            );
            return;
        }
        try {
            const cancelledPreorders = await getCancelledPreordersAPI(address, isLender, nPage, first);
            dispatch(getCancelledPreordersSuccess({ cancelledPreorders: cancelledPreorders.preorders, total: cancelledPreorders.numberOfPreorders }))
        } catch (error: any) {
            dispatch(
                getCancelledPreordersFailed({
                    error: 'address is not defined in getCancelledPreorders'
                })
            );
            console.error(error.message);
        }
    }
}

export const cancelPreorder = (preorderID: string, calledByLender: boolean) => {
    return async (dispatch: Dispatch, getState: () => RootState): Promise<string[]> => {
        dispatch(cancelPreorderStarted());
        const currentProfile = getState().user.currentProfile;
        const errors: string[] = [];
        if (currentProfile == null) {
            dispatch(
                cancelPreorderFailed({
                    error: 'currentProfile is not defined in getAcceptedPreorders'
                })
            );
            return errors;
        }
        const address = currentProfile.additional_properties?.commonshoodWallet;
        if (address == null) {
            dispatch(
                cancelPreorderFailed({
                    error: 'address is not defined in getAcceptedPreorders'
                })
            );
            return errors;
        }
        const ethers = getState().ethers.ethersInstance;
        if (ethers == null) {
            dispatch(
                cancelPreorderFailed({
                    error: 'wallet is not defined in getAcceptedPreorders'
                })
            );
            return errors;
        }
        try {
            await cancelPreorderAPI(ethers, address, preorderID);
            dispatch(cancelPreorderSuccess());
            if (calledByLender) {
                await reimburseTokens(ethers, address, preorderID);
            }
        } catch (error: any) {
            if (error.message) {
                const message = error.message as string;
                if (message.includes(`You can't cancel a preorder you didn't request`))
                    errors.push(`You can't cancel a preorder you didn't request`);
                else if (message.includes(`To cancel a preorder it must not be started or denied`)) {
                    errors.push(`To cancel a preorder it must not be started or denied`);
                } else if (message.includes(`Cannot set address(0) as valid address`)) {
                    errors.push(`Cannot set address(0) as valid address`);
                } else if (message.includes(`There's no item with this ID registered in the marketplace. Please provide a valid ID`)) {
                    errors.push(`There's no item with this ID registered in the marketplace. Please provide a valid ID`);
                } else {
                    console.log(message);
                }
            } else {
                console.log(error);
            }
            dispatch(cancelPreorderFailed(
                { error: error.message }
            ))
            console.error(error);
        }
        return errors;
    }
}

export const startPreorder = (preorderID: string) => {
    return async (dispatch: Dispatch, getState: () => RootState): Promise<string[] | undefined> => {
        let errors: string[] = [];
        dispatch(startPreorderStarted());
        console.log(`Starting preorder...${preorderID}`);
        const currentProfile = getState().user.currentProfile;
        if (currentProfile == null) {
            dispatch(
                startPreorderFailed({
                    error: 'currentProfile is not defined in startPreorder'
                })
            );
            errors.push('Current profile is not defined')
            return errors;
        }
        const address = currentProfile.additional_properties?.commonshoodWallet;
        if (address == null) {
            dispatch(
                startPreorderFailed({
                    error: 'address is not defined in startPreorder'
                })
            );
            errors.push('Address is not defined');
            return errors;
        }
        const ethers = getState().ethers.ethersInstance;
        if (ethers == null) {
            dispatch(
                startPreorderFailed({
                    error: 'wallet is not defined in startPreorder'
                })
            );
            errors.push('Wallet is not defined');
            return errors;
        }
        try {
            await startPreorderAPI(ethers, address, preorderID);
            dispatch(startPreorderSuccess());
        } catch (error: any) {
            const errorMessage = error.message;
            dispatch(startPreorderFailed(
                { error: error.message }
            ))
            if (errorMessage.includes('The starting period for this item is not started yet')) {
                throw new Error('The starting period for this item is not started yet');
            } else if (errorMessage.includes('The preorder ID you provided is not valid')) {
                throw new Error('The preorder ID you provided is not valid');
            } else if (errorMessage.includes('Only the lender of this item can start the lending period.')) {
                throw new Error('Only the lender of this item can start the lending period.');
            } else if (errorMessage.includes('The lending period for this item has passed.')) {
                throw new Error('The lending period for this item has passed.');
            } else if (errorMessage.includes('This preorder must be in status: ACCEPTED')) {
                throw new Error('This preorder must be in status: ACCEPTED');
            } else {
                console.log(error);
                throw new Error('Something went wrong');
            }


        }
        return errors;
    }
}

export const endLendingPeriod = (preorderID: string, comment: string, itemWasFine: boolean) => {
    return async (dispatch: Dispatch, getState: () => RootState): Promise<string[] | undefined> => {
        let errors: string[] = [];
        dispatch(endLendingPeriodStarted());
        console.log(`Ending lending period for preorder...${preorderID}`);
        const currentProfile = getState().user.currentProfile;
        if (currentProfile == null) {
            dispatch(
                endLendingPeriodFailed({
                    error: 'currentProfile is not defined in endLendingPeriod'
                })
            );
            errors.push('Current profile is not defined')
            return errors;
        }
        const address = currentProfile.additional_properties?.commonshoodWallet;
        if (address == null) {
            dispatch(
                endLendingPeriodFailed({
                    error: 'address is not defined in endLendingPeriod'
                })
            );
            errors.push('Address is not defined');
            return errors;
        }
        const ethers = getState().ethers.ethersInstance;
        if (ethers == null) {
            dispatch(
                endLendingPeriodFailed({
                    error: 'wallet is not defined in endLendingPeriod'
                })
            );
            errors.push('Wallet is not defined');
            return errors;
        }

        try {
            await endLendingPeriodAPI(ethers, address, preorderID, comment, itemWasFine);
            dispatch(endLendingPeriodSuccess());
        } catch (error: any) {
            const errorMessage = error.message;
            if (errorMessage.includes('Only the borrower of item in the preorder can end the lending period')) {
                errors.push('Only the borrower of item in the preorder can end the lending period');
            } else if (errorMessage.includes('This preorder never started or it is already ENDED')) {
                errors.push('This preorder never started or it is already ENDED');
            } else if (errorMessage.includes('Collection address not valid')) {
                errors.push('Collection address not valid');
            } else {
                console.log(error);
            }
            dispatch(endLendingPeriodFailed(
                { error: error.message }
            ))

        }
        return errors;
    }
}

export const endRevisionPeriod = (preorderID: string, comment: string, itemIsFine: boolean) => {
    return async (dispatch: Dispatch, getState: () => RootState): Promise<string[] | undefined> => {
        let errors: string[] = [];
        dispatch(endRevisionPeridoStart());
        console.log(`Ending revision period for preorder...${preorderID}`);
        const currentProfile = getState().user.currentProfile;

        if (currentProfile == null) {
            dispatch(
                endRevisionPeriodFailed({
                    error: 'currentProfile is not defined in endRevisionPeriod'
                })
            );
            errors.push('Current profile is not defined')
            return errors;
        }
        const address = currentProfile.additional_properties?.commonshoodWallet;
        if (address == null) {
            dispatch(
                endRevisionPeriodFailed({
                    error: 'address is not defined in endRevisionPeriod'
                })
            );
            errors.push('Address is not defined');
            return errors;
        }
        const ethers = getState().ethers.ethersInstance;

        if (ethers == null) {
            dispatch(
                endRevisionPeriodFailed({
                    error: 'wallet is not defined in endRevisionPeriod'
                })
            );
            errors.push('Wallet is not defined');
            return errors;
        }
        try {
            await endRevisionPeriodAPI(ethers, address, comment, itemIsFine, preorderID);
            dispatch(endRevisionPeriodSuccess());
        } catch (error: any) {
            const errorMessage = error.message;
            if (errorMessage.includes("You're not the lender of the item of this preorder. So you can't end the revision.")) {
                errors.push("You're not the lender of the item of this preorder. So you can't end the revision.");
            } else if (errorMessage.includes("The borrower didn't return the item yet. Wait for him to start the revision period")) {
                errors.push("The borrower didn't return the item yet. Wait for him to start the revision period");
            } else if (errorMessage.includes('The refund cannot be given to an invalid address.')) {
                errors.push('The refund cannot be given to an invalid address.');
            } else {
                console.log(error);
            }
            dispatch(endRevisionPeriodFailed(
                { error: error.message }
            ))

        }
        return errors;
    }
}

export const getEndedPreorders = (isLender: boolean, offset: number) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        console.log(`get ended preorders slice was called`);
        dispatch(getEndedPreordersStarted());
        const currentProfile = getState().user.currentProfile;
        if (currentProfile == null) {
            dispatch(
                getEndedPreordersFailed({
                    error: 'currentProfile is not defined in endRevisionPeriod'
                })
            );
            return;
        }
        const address = currentProfile.additional_properties?.commonshoodWallet;
        if (address == null) {
            dispatch(
                getEndedPreordersFailed({
                    error: 'address is not defined in endRevisionPeriod'
                })
            );
            return;
        }
        try {
            const preorders = await getPreorders(address, isLender, offset, getState().calendar.preordersPerPage, 'Review');
            dispatch(getEndedPreordersSuccess({ preorders: preorders.preorders, total: preorders.numberOfPreorders }));
        } catch (error: any) {
            console.log(error);
            dispatch(endRevisionPeriodFailed(
                { error: error.message }
            ))

        }
    }
}

export const getPreordersUnderReview = (isLender: boolean, offset: number) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        console.log(`get preorders with status review slice was called`);
        dispatch(getPreordersUnderReviewStarted());
        const currentProfile = getState().user.currentProfile;
        if (currentProfile == null) {
            dispatch(
                getPreordersUnderReviewFailed({
                    error: 'currentProfile is not'
                })
            );
            return;
        }
        const address = currentProfile.additional_properties?.commonshoodWallet;
        if (address == null) {
            dispatch(
                getPreordersUnderReviewFailed({
                    error: 'address is not defined'
                })
            );
            return;
        }
        try {
            const preorders = await getPreordersWithStatusEnded(address, isLender, offset, getState().calendar.preordersPerPage);
            dispatch(getPreordersUnderReviewSuccess({ preorders: preorders.preorders, total: preorders.numberOfPreorders }));
        } catch (error: any) {
            console.log(error);
            dispatch(getPreordersUnderReviewFailed(
                { error: error.message }
            ))
        }
    }
}

export const getDeniedPreorders = (isLender: boolean, offset: number) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        console.log(`get denied preorders slice was called`);
        dispatch(getDeniedPreordersStarted());
        const currentProfile = getState().user.currentProfile;
        if (currentProfile == null) {
            dispatch(
                getDeniedPreordersFailed({
                    error: 'currentProfile is not defined in endRevisionPeriod'
                })
            );
            return;
        }
        const address = currentProfile.additional_properties?.commonshoodWallet;
        if (address == null) {
            dispatch(
                getDeniedPreordersFailed({
                    error: 'address is not defined in endRevisionPeriod'
                })
            );
            return;
        }
        try {
            const preorders = await getPreorders(address, isLender, offset, getState().calendar.preordersPerPage, 'Denied');
            dispatch(getDeniedPreordersSuccess({ preorders: preorders.preorders, total: preorders.numberOfPreorders }));
        } catch (error: any) {
            console.log(error);
            dispatch(endRevisionPeriodFailed(
                { error: error.message }
            ))

        }
    }
}

export const isAddressPaused = (address: string) => {
    return async (dispatch: Dispatch, getState: () => RootState): Promise<boolean> => {
        let isPaused = false;
        try {
            dispatch(getPausedUserStarted());
            const currentProfile = getState().user.currentProfile;
            if (currentProfile) {
                const ethers = getState().ethers.ethersInstance;
                if (ethers) {
                    const contract = getCalendarInstance(ethers);
                    isPaused = await contract.isUserFreezed(address);
                } else {
                    console.log(`Ethers was null or undefined`);
                }
            } else {
                console.log(`Current profile was null or undefined`);
            }
        } catch (error) {
            console.log(error);
        } finally {
            dispatch(getPausedUserEnded({ paused: isPaused }));
        }
        return isPaused;
    }
}

export const togglePauseStatus = () => {
    return async (dispatch: Dispatch, getState: () => RootState): Promise<boolean> => {
        try {
            dispatch(togglePauseStarted());
            const currentProfile = getState().user.currentProfile;
            if (currentProfile) {
                const ethers = getState().ethers.ethersInstance;
                if (ethers) {
                    const contract = getCalendarInstance(ethers);
                    console.log(`Freezing user`);
                    const isPaused = await contract.isUserFreezed(currentProfile.additional_properties?.commonshoodWallet);
                    if (isPaused) {
                        const trx = await contract.unfreezeUser();
                        const receipt = await trx.wait();
                        dispatch(togglePauseEnded({ paused: false }));
                        console.log(receipt);
                    } else {
                        const trx = await contract.freezeUser();
                        const receipt = await trx.wait();
                        dispatch(togglePauseEnded({ paused: true }));
                        console.log(receipt);
                    }


                } else {
                    console.log(`Ethers was null or undefined`);
                }
            } else {
                console.log(`Current profile was null or undefined`);
            }
        } catch (error) {
            console.log(error);
        }


        return true;
    }
}


export default calendarSlice.reducer;