import { Grid } from "@material-ui/core"
import { useAppSelector } from "../../../../../store/hooks"
import { Skeleton } from "@material-ui/lab"
import { useContext, useEffect, useState } from "react";
import { Preorder } from "../../../../../types/calendar.type";
import { PaginationContainer } from "../../../../../components/UI/Pagination/PaginationContainer";
import { getPreorders } from "../../../../../api/calendar";
import { PreorderPendingInList } from "../Pending/PreorderPendingInList";
import { CalendarContractContext } from "../../../../../providers/CalendarContractProvider";
import { NoItemsDisplay } from "../../../BasicComponents/NoItemsDisplay";
import { delay } from "../../../../../utilities/timer/wait";

export const ListOfPreordersCompleted = ({ shouldRefresh }: {
    shouldRefresh: boolean
}) => {
    const preordersPerPage = useAppSelector(state => state.calendar.preordersPerPage);
    const loading = useAppSelector(state => state.calendar.loading);
    const account = useAppSelector(state => state.user.currentProfile);
    const [preordersToShow, setPreordersToShow] = useState<Preorder[]>([]);
    const [numberOfPreorders, setNumberOfPreorders] = useState<number>(0);
    const { getUserStats } = useContext(CalendarContractContext);

    useEffect(() => {
        (async () => {
            await getPreordersFromMetadata(0);
        })()
    }, [])

    useEffect(() => {
        (async () => {
            await getPreordersFromMetadata(0);
        })()
    }, [shouldRefresh])

    const handleClickOnPagination = async (selectedPage: number) => {
        await getPreordersFromMetadata(selectedPage);
    }

    const getPreordersFromMetadata = async (page: number) => {
        const address = account?.additional_properties?.commonshoodWallet;
        if (address) {
            const activePreorders = await getPreorders(address, true, page * preordersPerPage, preordersPerPage, 'Ended');
            setPreordersToShow(activePreorders.preorders);
            setNumberOfPreorders(activePreorders.numberOfPreorders);
        }
    }

    return (
        <Grid container style={{ position: 'relative', top: '3em' }} spacing={3}>
            {
                preordersToShow.length === 0 ? (
                    <NoItemsDisplay style={{ marginTop: '3em' }} />
                ) : (
                    <>
                        {
                            preordersToShow.map((p) => {
                                return <Grid style={{ marginBottom: '100px', margin: '1em' }} key={p.preorderID} item xs={12}>
                                    <PreorderPendingInList
                                        preorder={p}
                                        callback={async () => {
                                            await delay(1000);
                                            await getUserStats();
                                        }}
                                        textUnderStepper="Completata"
                                    />
                                </Grid>
                            })
                        }
                    </>
                )
            }
            <Grid item xs={12} style={{ marginBottom: '15em' }}>
                <PaginationContainer pageCount={Math.ceil(numberOfPreorders / preordersPerPage)} onChange={handleClickOnPagination} />
            </Grid>
        </Grid>
    )
}