import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { logger } from "../../utilities/logger/logger";
import { assetsType } from "../../config/assets";
import { uploadFileIpfs } from "../../api/resourceAPI";
import {
    coinGetBalance as coinGetBalanceAPI,
    coinSend as coinSendAPI,
    createCoin,
    getAllTokenAddresses,
    getCoinTransactions,
    getContractHash,
    getLogoHash,
    getPossessedTokens,
    getTokenByAddress,
    getTokenBySymbol,
    getTokensFromMetadata,
    getTokensOwnedFromMetadata,
    getTokenWithBalanceFromMetadata,
    mintCoin,
} from "../../api/coinAPI";
import { Event } from "ethers";

import { assetDecimalRepresentationToInteger } from "../../utilities/decimalsHandler/decimalsHandler";

import type { UserData } from "../../types/user.type";

import { NotificationType } from "../../api/notificationAPI";
import config from "../../config";
import { Token } from "../../types/coin.type";
import { getIpfsUrl } from "../../utilities/ipfs/ipfs";

type CoinInitialState = {
    error: string | null;
    coinCreationLoading: boolean;
    getListloading: boolean;
    balanceLoading: boolean;
    coinCreated: boolean;
    coinMintingAfterCreation: boolean;
    coinSent: boolean;
    coinSendLoading: boolean;
    mintLoading: boolean;
    coinMinted: boolean;
    mintError: null | string;
    loadingTransactions: boolean;
    transactions: any[]; //TODO fix this, we need to define a type for transactions
    coinList: any[]; //TODO fix this
    coinListForPiggies: any[]; //TODO fix this
    loadingCoinListForPiggies: boolean;
    preselectedCoin: any | undefined; //TODO fix this
    paginationHandler: boolean;
    waitingForSend: boolean;
};

const initialState: CoinInitialState = {
    error: null,
    coinCreationLoading: false,
    getListloading: false,
    balanceLoading: false,
    coinCreated: false,
    coinMintingAfterCreation: false,
    coinSent: false,
    coinSendLoading: false,

    mintLoading: false,
    coinMinted: false,
    mintError: null,

    loadingTransactions: false,
    transactions: [],

    coinList: [],

    coinListForPiggies: [],
    loadingCoinListForPiggies: false,

    preselectedCoin: undefined, //{ticker, icon, type}
    paginationHandler: true,
    //contractsCache: new Map(),
    waitingForSend: true,
};

export const coinSlice = createSlice({
    name: "coin",
    initialState,
    reducers: {
        //coin creation reducers:
        coinCreateReset(state) {
            state.coinCreated = false;
            state.coinMintingAfterCreation = false;
            state.coinCreationLoading = false;
            state.error = null;
        },
        coinCreateStart(state) {
            state.coinCreated = false;
            state.coinMintingAfterCreation = false;
            state.coinCreationLoading = true;
        },
        coinCreateSuccess(state) {
            state.coinCreationLoading = false;
            state.coinCreated = true;
            state.coinMintingAfterCreation = false;
        },
        coinMintAfterCreation(state) {
            state.coinMintingAfterCreation = true;
        },
        coinCreateFail(state, action: PayloadAction<{ error: string }>) {
            state.coinCreationLoading = false;
            state.error = action.payload.error;
        },
        //=========================================================
        //coin get list of user
        coinGetListStart(state) {
            state.getListloading = true;
        },
        coinGetListSuccess(state, action: PayloadAction<{ coinList: any[] }>) {
            //TODO fixme action type
            state.getListloading = false;
            state.coinList = action.payload.coinList
                .slice()
                .sort((a, b) => a.name.localeCompare(b.name));
        },
        coinGetListFail(state, action: PayloadAction<{ error: string }>) {
            state.getListloading = false;
            state.error = action.payload.error;
        },
        coinGetListReset(state) {
            state.getListloading = false;
            state.coinList = []; //CHECK THIS, in original store was setting at null
        },
        //=========================================================
        //coin get list for piggies
        coinForPiggiesGetListReset(state) {
            state.loadingCoinListForPiggies = false;
            state.coinListForPiggies = [];
        },
        coinForPiggiesGetListStart(state) {
            state.loadingCoinListForPiggies = true;
        },
        coinForPiggiesGetListSuccess(
            state,
            action: PayloadAction<{ coinListForPiggies: any[] }>
        ) {
            state.loadingCoinListForPiggies = false;
            state.coinListForPiggies = action.payload.coinListForPiggies
                .slice()
                .sort((a, b) => a.name.localeCompare(b.name));
        },
        coinForPiggiesGetListFail(state, action: PayloadAction<{ error: string }>) {
            state.loadingCoinListForPiggies = false;
            state.error = action.payload.error;
        },
        //Get balance of specific coin (from symbol)
        //TODO potentially remove this from store and use it directly in the components?
        coinGetBalanceReset(state) {
            state.balanceLoading = false;
        },
        coinGetBalanceStart(state) {
            state.balanceLoading = true;
        },
        coinGetBalanceSuccess(
            state,
            action: PayloadAction<{
                coinBalance: { balance: number; symbol: string };
                forPiggies: boolean;
            }>
        ) {
            const newBalance = action.payload.coinBalance;
            if (action.payload.forPiggies) {
                //working with the piggies' coinlist
                if (
                    state.coinListForPiggies == null ||
                    state.coinListForPiggies.length === 0
                ) {
                    return state;
                }

                const piggiesCoinList = [...state.coinListForPiggies];
                const coinToUpdateIndex = piggiesCoinList.findIndex(
                    (item) => item.symbol === newBalance.symbol
                );
                if (coinToUpdateIndex === -1) {
                    return state;
                }
                const coinToUpdateItem = piggiesCoinList[coinToUpdateIndex];
                piggiesCoinList[coinToUpdateIndex] = {
                    ...coinToUpdateItem,
                    balance: newBalance.balance,
                };

                state.coinListForPiggies = piggiesCoinList;
            } else {
                if (state.coinList == null || state.coinList.length === 0) {
                    return state;
                }
                const coinList = [...state.coinList];
                const coinToUpdateIndex = coinList.findIndex(
                    (item) => item.symbol === newBalance.symbol
                );
                if (coinToUpdateIndex === -1) {
                    return state;
                }
                const coinToUpdateItem = coinList[coinToUpdateIndex];

                coinList[coinToUpdateIndex] = {
                    ...coinToUpdateItem,
                    balance: newBalance.balance,
                };

                state.coinList = coinList;
            }
        },
        coinGetBalanceFail(state, action: PayloadAction<{ error: string }>) {
            state.balanceLoading = false;
            state.error = action.payload.error;
        },
        //=============================================================
        //sending coins
        coinSendStart(state) {
            state.waitingForSend = true;
            state.coinSent = false;
            state.coinSendLoading = true;
        },
        coinSendSuccess(state) {
            state.coinSendLoading = false;
            if (state.waitingForSend) {
                state.coinSent = true;
            }
        },
        coinSendFail(state, action: PayloadAction<{ error: string }>) {
            state.coinSendLoading = false;
            if (state.waitingForSend) {
                state.coinSent = false;
            }
            state.error = action.payload.error;
        },
        coinSendReset(state) {
            state.coinSendLoading = false;
            state.coinSent = false;
            state.error = null;
        },
        notWaitingForSend(state) {
            state.waitingForSend = false;
        },
        //=================================================================
        //minting coins
        coinMintStart(state) {
            state.coinMinted = false;
            state.mintLoading = true;
        },
        coinMintSuccess(state) {
            state.mintLoading = false;
            state.mintLoading = false;
            state.coinMinted = true;
        },
        coinMintFail(state, action: PayloadAction<{ error: string }>) {
            state.mintLoading = false;
            state.coinMinted = false;
            state.mintError = action.payload.error;
        },
        coinMintReset(state) {
            state.mintLoading = false;
            state.coinMinted = false;
            state.mintError = null;
        },
        //===================================================================
        //transactions
        coinTransactionsStart(state) {
            state.loadingTransactions = true;
            state.transactions = [];
        },
        coinTransactionsSuccess(
            state,
            action: PayloadAction<{ transactions: NotificationType[] }>
        ) {
            //TODO fixme
            let newTransactions = action.payload.transactions
                ? action.payload.transactions
                : [];
            for (let not in newTransactions) {
                state.transactions.push(newTransactions[not]);
            }
            state.loadingTransactions = false;
        },
        coinTransactionsFail(state, action: PayloadAction<{ error: string }>) {
            state.loadingTransactions = false;
            state.error = action.payload.error;
        },
        coinTransactionReset(state) {
            state.transactions = [];
            state.loadingTransactions = false;
            state.error = null;
        },
        //===================================================================
        //preselection coin
        coinSetPreselected(state, action: PayloadAction<{ coin: any }>) {
            //TODO fixme
            state.preselectedCoin = action.payload.coin;
        },
        coinUnsetPreselected(state) {
            state.preselectedCoin = undefined;
        },
        transactionsPaginationEnable(state) {
            state.paginationHandler = true;
        },
        transactionsPaginationDisable(state) {
            state.paginationHandler = false;
        },
        transactionsPaginationReset(state) {
            state.transactions = [];
        },
    },
});

export const {
    coinCreateReset,
    coinCreateSuccess,
    coinCreateStart,
    coinCreateFail,
    coinMintAfterCreation,
    coinForPiggiesGetListReset,
    coinForPiggiesGetListStart,
    coinGetListReset,
    coinGetListStart,
    coinGetListSuccess,
    coinForPiggiesGetListSuccess,
    coinGetBalanceReset,
    coinGetBalanceSuccess,
    coinGetBalanceFail,
    coinSendStart,
    coinSendSuccess,
    coinSendFail,
    coinSendReset,
    coinMintStart,
    coinMintFail,
    coinMintSuccess,
    coinMintReset,
    coinUnsetPreselected,
    coinSetPreselected,
    coinTransactionsStart,
    coinTransactionsSuccess,
    coinTransactionsFail,
    coinTransactionReset,
    transactionsPaginationReset,
    transactionsPaginationDisable,
    transactionsPaginationEnable,
    notWaitingForSend,
} = coinSlice.actions;

export type CoinData = {
    name: string;
    symbol: string;
    description: string;
    decimals: number;
    initialSupply: number;
    iconFile: any; //TODO fixme
    contractFile: File | null;
    type: string;
};

export const coinCreate = (coinData: CoinData) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        dispatch(coinCreateStart());
        const {
            name,
            symbol,
            description, //TODO we must still modify the contract to add description
            decimals,
            initialSupply,
            iconFile,
            contractFile,
            type,
        } = coinData;
        const realInitialSupply = parseInt(
            assetDecimalRepresentationToInteger(initialSupply, decimals)
        );

        let iconHash = null;
        let iconUrl = null;
        let contractHash = '';

        const currentProfile = getState().user.currentProfile;
        const ethers = getState().ethers.ethersInstance;

        if (currentProfile == null || ethers == null) {
            dispatch(
                coinCreateFail({ error: `currentProfile is not defined in CoinCreate` })
            );
            return;
        }
        const realm = currentProfile.realm;

        try {
            const iconResponse = await uploadFileIpfs(iconFile);
            iconHash = config.network.ipfs.default_url + iconResponse;
            iconUrl = config.network.ipfs.default_url + iconResponse;
            // Upload Contract File
            if (contractFile != null) {
                const contractResponse = await uploadFileIpfs(contractFile);
                contractHash = config.network.ipfs.default_url + contractResponse;
            }


            let ccdaoAddress: string | undefined;
            let accountAddress: string | undefined;
            //checking if transaction is made by a user or a dao

            //we need both the address of the dao and the address of the user which has to call its contract's methods
            ccdaoAddress = currentProfile.additional_properties?.commonshoodWallet;
            if (ccdaoAddress == null) {
                dispatch(
                    coinCreateFail({
                        error: `Something went wrong, the dao trying to start the transaction has no address ${currentProfile}`,
                    })
                );
                return;
            }
            //getting the wallet also of the user who is "logged" as dao
            accountAddress =
                getState().user.userProfile?.additional_properties?.commonshoodWallet;
            if (realm === "user") {
                accountAddress =
                    currentProfile.additional_properties?.commonshoodWallet;
            }
            if (accountAddress == null) {
                dispatch(coinCreateFail({ error: "account address is undefined" }));
                return;
            }


            try {
                let creationResponse; //fixme add type
                let justCreatedTokenAddress: string;
                creationResponse = await createCoin(ethers, accountAddress, {
                    name,
                    symbol,
                    cap: realInitialSupply,
                    decimals,
                    iconHash,
                    iconUrl,
                    contractHash,
                });

                const event = creationResponse.events.filter((ev: Event) => ev.event === "TokenAdded")
                logger.info("event: ", event)
                justCreatedTokenAddress = event[0].args[1]
                logger.info("succesfully created res: ", justCreatedTokenAddress);
                dispatch(coinCreateSuccess());
            } catch (error: any) {
                logger.debug("Something went bad while creating coin:", error);
                dispatch(coinCreateFail({ error }));
            }
        } catch (error: any) {
            dispatch(coinCreateFail({ error }));
        }
    };
};

//helper function to know if an asset is a coupon(=goods) or token
const getCoinType = (decimals: number) => {
    if (decimals === 0) {
        return assetsType.goods.name;
    }
    return assetsType.token.name;
};

export const coinGetList = (
    type: string | null,
    withBalance: boolean = true,
    onlyOwned: boolean | null,
    forPiggies: boolean,
    tokensAddressesArrayFilter: string[] | null //it is an array of addresses to select
) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        if (forPiggies) {
            dispatch(coinForPiggiesGetListReset());
            dispatch(coinForPiggiesGetListStart());
        } else {
            dispatch(coinGetListReset());
            dispatch(coinGetListStart());
        }

        let coinsList = [];
        const ethers = getState().ethers.ethersInstance;

        const currentProfile = getState().user.currentProfile;
        if (!currentProfile || !ethers) {
            dispatch(
                coinCreateFail({
                    error: `Something went wrong, current profile is undefined! ${currentProfile}`,
                })
            );
            return;
        }

        try {
            const accountAddress =
                currentProfile.additional_properties?.commonshoodWallet;
            if (accountAddress == null) {
                dispatch(
                    coinCreateFail({
                        error: `Something went wrong, wallet address of current profile is undefined! ${currentProfile}`,
                    })
                );
                return;
            }

            let tokensAddresses = [];
            if (onlyOwned) {
                tokensAddresses = await getPossessedTokens(ethers, accountAddress);
                logger.debug(
                    "[coin - coinGetList] getPossessedTokens success res: ",
                    tokensAddresses
                );
            } else {
                tokensAddresses = await getAllTokenAddresses(ethers, accountAddress);
                logger.debug(
                    "[coin - coinGetList] getAllTokenAddresses success res: ",
                    tokensAddresses
                );
            }

            if (tokensAddressesArrayFilter != null) {
                //apply extra filter, we want just some of these addresses
                tokensAddresses = tokensAddresses.filter((address) => {
                    return tokensAddressesArrayFilter.includes(address);
                });
            }

            if (tokensAddresses.length !== 0) {
                //this user posses some coin
                const tokensExtendedDataPromises = tokensAddresses.map(
                    (tokenTemplateAddress) => {
                        return getTokenByAddress(
                            ethers,
                            accountAddress,
                            tokenTemplateAddress
                        );
                    }
                );
                const tokensExtendedData = await Promise.all(
                    tokensExtendedDataPromises
                );

                // const tokenInstances = tokensAddresses.map( (tokenTemplateAddress) => {
                //     return new ethers.eth.Contract(
                //         config.smartContracts.TKN_TMPLT_ABI,
                //         tokenTemplateAddress,
                //     );
                // });

                let logo = null;
                for (let i = 0; i < tokensExtendedData.length; i++) {
                    const token = tokensExtendedData[i];
                    if (token !== null) {
                        const tokenAddress = token.contractAddress;
                        const coinOwner = token.owner;
                        const name = token.name;
                        const symbol = token.symbol;
                        const decimals = token.decimals;
                        const logoHash = await getLogoHash(
                            ethers,
                            accountAddress,
                            tokenAddress
                        );
                        const contractHash = await getContractHash(
                            ethers,
                            accountAddress,
                            tokenAddress
                        );
                        if (logoHash === null || contractHash === null) {
                            throw `failed to load some resources:
                                logohash ${logoHash}
                                contractHash ${contractHash}`;
                        }

                        let balance = null;
                        if (withBalance) {
                            try {
                                balance = await coinGetBalanceAPI(
                                    ethers,
                                    accountAddress,
                                    tokenAddress
                                );
                                console.log(
                                    `Balance for ${symbol} is ${balance["balance"]} with ${decimals} decimals`
                                );
                            } catch {
                                balance = -1;
                            }
                        }

                        const coinData = {
                            address: tokenAddress,
                            symbol,
                            logo: getIpfsUrl(logoHash),
                            logoFile: logo,
                            balance,
                            decimals,
                            //cap: coinItem.cap, //not needed
                            name,
                            //userId: coinItem.userId, //here i need the creator address instead
                            addressOfOwner: coinOwner,
                            //description: coinItem.description, //TODO Add in contract first
                            ownerType: "user", //coinItem.ownerType,
                            contractHash: contractHash,
                            type: getCoinType(decimals),
                        };
                        coinsList.push(coinData);
                    }
                }
            }

            if (coinsList.length !== 0 && type != null) {
                //filter on type
                coinsList = coinsList.filter((coin) => coin.type === type);
            }
            console.log("coinList: ", coinsList);
            //if the user did not posses coin, coinsList is still === []
            if (forPiggies) {
                dispatch(
                    coinForPiggiesGetListSuccess({ coinListForPiggies: coinsList })
                );
                return;
            } else {
                dispatch(coinGetListSuccess({ coinList: coinsList }));
                return;
            }
        } catch (error: any) {
            logger.debug("[coin - coinGetList] something went wrong:", error);
            dispatch(coinCreateFail({ error }));
            return;
        }
    };
};

export const coinGetBalance = (
    symbol: string,
    coinAddress: string,
    forPiggies: boolean
) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        dispatch(coinGetBalanceReset());
        const ethers = getState().ethers.ethersInstance;

        const currentProfile = getState().user.currentProfile;
        if (!currentProfile || !ethers) {
            dispatch(
                coinGetBalanceFail({
                    error: `currentProfile is undefined in coinGetBalance ${currentProfile}`,
                })
            );
            return;
        }
        try {
            const accountAddress =
                currentProfile.additional_properties?.commonshoodWallet;
            if (!accountAddress) {
                dispatch(
                    coinGetBalanceFail({
                        error: `wallet address is undefined in coinGetBalance ${currentProfile}`,
                    })
                );
                return;
            }
            const { balance, decimals } = await coinGetBalanceAPI(
                ethers,
                accountAddress,
                coinAddress
            );

            logger.debug(
                `Balance for ${symbol} is ${balance} with ${decimals} decimals`
            );

            const coinBalance = {
                symbol: symbol,
                balance: balance,
                decimals: decimals,
            };
            dispatch(coinGetBalanceSuccess({ coinBalance, forPiggies }));
        } catch (error: any) {
            logger.debug(`error while retriving balance for ${symbol}: ${error}`);
            dispatch(coinGetBalanceFail({ error }));
        }
    };
};

//TODO refine type for coinData and check from which component it is coming
// so we can add it there too
export type CoinSendData = {
    recipient: UserData | undefined | null;
    symbol: string;
    decimals: number;
    amount: string;
};

export const coinSend = (coinData: CoinSendData) => {
    logger.info("COIN DATA: ", coinData);
    return async (dispatch: Dispatch, getState: () => RootState) => {
        const recipient = coinData.recipient;
        const symbol = coinData.symbol;
        const decimals = coinData.decimals;
        console.log("coinData.amount: ", coinData.amount);
        console.log(recipient);
        const amount = parseInt(
            assetDecimalRepresentationToInteger(coinData.amount, decimals)
        );

        const ethers = getState().ethers.ethersInstance;

        const currentProfile = getState().user.currentProfile;

        dispatch(coinSendStart());

        if (!currentProfile || !ethers) {
            console.log(`No currentProfile found`)
            dispatch(
                coinSendFail({
                    error: `Something went wrong, current profile is undefined! ${currentProfile}`,
                })
            );
            return;
        }
        let accountAddress: string | undefined;
        accountAddress = currentProfile.additional_properties?.commonshoodWallet;

        if (accountAddress == null) {
            console.log(`No accountAddress found`)
            dispatch(
                coinSendFail({
                    error: `Something went wrong, wallet address of current profile is undefined! ${currentProfile}`,
                })
            );
            return;
        }

        try {
            if (!recipient) {
                dispatch(
                    coinSendFail({
                        error: `Destination recipient in coinData is undefined: ${recipient}`,
                    })
                );
                return;
            }
            const tokenData = await getTokenBySymbol(ethers, accountAddress, symbol);
            let tokenAddress: string | null;
            if (tokenData !== null) {
                tokenAddress = tokenData.contractAddress;
            } else {
                console.log(`Could not find the token data`)
                dispatch(coinSendFail({ error: `Could not find the token data` }));
                return;
            }
            const recipientWalletAddress =
                recipient.additional_properties?.commonshoodWallet;

            if (recipientWalletAddress == null) {
                dispatch(
                    coinSendFail({
                        error: `Receiver wallet is empty or not defined ${recipient}`,
                    })
                );
                return;
            }
            console.log(`Flag`)
            await coinSendAPI(
                ethers,
                tokenAddress,
                accountAddress,
                recipientWalletAddress,
                amount
            );

            dispatch(coinSendSuccess());
        } catch (error: any) {
            dispatch(
                coinSendFail({
                    error: error.response
                        ? error.response.statusText
                        : "Error in coinsend",
                })
            );
        }
    };
};

export const coinMint = (
    tokenAddress: string,
    symbol: string,
    amount: number,
    decimals: number
) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        dispatch(coinMintReset());
        dispatch(coinMintStart());
        const ethers = getState().ethers.ethersInstance;

        const currentProfile = getState().user.currentProfile;
        const realAmount = parseInt(
            assetDecimalRepresentationToInteger(amount, decimals)
        );
        if (!currentProfile || !ethers) {
            dispatch(
                coinMintFail({
                    error: `currentProfile is undefined in coinGetBalance ${currentProfile}`,
                })
            );
            return;
        }
        try {
            let accountAddress: string | undefined;
            //we need to act differently if the mint is a request of a dao OR directly of a user

            accountAddress =
                currentProfile.additional_properties?.commonshoodWallet;

            if (accountAddress == null) {
                dispatch(
                    coinMintFail({
                        error: `wallet undefined in profile in coinMint: ${currentProfile}`,
                    })
                );
                return;
            }

            let mintingResponse: any; //fixme
            mintingResponse = await mintCoin(
                ethers,
                accountAddress,
                tokenAddress,
                realAmount
            );

            dispatch(coinMintSuccess());
            logger.info("succesfully minted res: ", mintingResponse);
        } catch (error: any) {
            logger.error("error in coin mint ", error);
            dispatch(coinMintFail({ error }));
        }
    };
};

export const coinTransactions = (
    coinAddress: string,
    page: number,
    amount: number
) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        dispatch(coinTransactionsStart());
        const currentProfile = getState().user.currentProfile;
        if (!currentProfile) {
            dispatch(
                coinTransactionsFail({
                    error: `currentProfile is undefined in coinGetTransactions ${currentProfile}`,
                })
            );
            return;
        }
        try {
            const accountAddress =
                currentProfile.additional_properties?.commonshoodWallet;
            if (!accountAddress) {
                dispatch(
                    coinTransactionsFail({
                        error: `wallet address is undefined in coinGetTransactions ${currentProfile}`,
                    })
                );
                return;
            }
            getCoinTransactions(page, amount, coinAddress)
                .then(async (res) => {
                    if (res.length === 0) {
                        dispatch(
                            coinTransactionsFail({
                                error: `wallet address is undefined in coinGetTransactions ${currentProfile}`,
                            })
                        );
                        dispatch(transactionsPaginationDisable());
                    } else {
                        dispatch(coinTransactionsSuccess({ transactions: res }));
                    }
                })
                .catch((error) => {
                    dispatch(
                        coinTransactionsFail({
                            error: `wallet address is undefined in coinGetTransactions ${currentProfile}`,
                        })
                    );
                });
        } catch (error: any) {
            logger.debug(`error while retrieving transactions`);
        }
    };
};

export const getTokensFM = (
    type: string,
    withBalance: boolean,
    forPiggies: boolean,
    page?: number
) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        if (forPiggies) {
            dispatch(coinForPiggiesGetListReset());
            dispatch(coinForPiggiesGetListStart());
        } else {
            dispatch(coinGetListReset());
            dispatch(coinGetListStart());
        }
        const currentProfile = getState().user.currentProfile;
        const ethers = getState().ethers.ethersInstance;

        if (currentProfile == null || ethers == null) {
            coinCreateFail({
                error: `Something went wrong, current profile is undefined! ${currentProfile}`,
            });
            return;
        }
        const accountAddress =
            currentProfile.additional_properties?.commonshoodWallet;
        if (accountAddress == null) {
            coinCreateFail({
                error: `Something went wrong, wallet address of current profile is undefined! ${currentProfile}`,
            });
            return;
        }
        try {
            const tokensList: Token[] = await getTokensFromMetadata(
                type,
                page ? page : -1
            );

            for (const token of tokensList) {
                token.logo = getIpfsUrl(token.logo);
                token.contractHash = getIpfsUrl(token.contractHash);
                token.type = token.decimals == 2 ? assetsType.token.name : assetsType.goods.name;
                if (withBalance)
                    token.balance = await coinGetBalanceAPI(
                        ethers,
                        accountAddress,
                        token.address
                    );
            }
            if (forPiggies) {
                dispatch(
                    coinForPiggiesGetListSuccess({ coinListForPiggies: tokensList })
                );
            } else {
                dispatch(coinGetListSuccess({ coinList: tokensList }));
            }
        } catch (error: any) {
            logger.debug("[coin - coinGetList] something went wrong:", error);
            dispatch(coinCreateFail({ error }));
            return;
        }
    };
};

export const getTokensOwnedFM = (
    type: string,
    withBalance: boolean,
    forPiggies: boolean,
    page?: number
) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        if (forPiggies) {
            dispatch(coinForPiggiesGetListReset());
            dispatch(coinForPiggiesGetListStart());
        } else {
            dispatch(coinGetListReset());
            dispatch(coinGetListStart());
        }
        const currentProfile = getState().user.currentProfile;
        if (currentProfile == null) {
            coinCreateFail({
                error: `Something went wrong, current profile is undefined! ${currentProfile}`,
            });
            return;
        }
        const accountAddress =
            currentProfile.additional_properties?.commonshoodWallet;
        if (accountAddress == null) {
            coinCreateFail({
                error: `Something went wrong, wallet address of current profile is undefined! ${currentProfile}`,
            });
            return;
        }
        try {
            const tokensList: Token[] = await getTokensOwnedFromMetadata(
                accountAddress,
                type,
                page ? page : -1,
                withBalance
            );
            for (const token of tokensList) {
                token.logo = getIpfsUrl(token.logo);
                token.contractHash = getIpfsUrl(token.contractHash);
                token.type = token.decimals == 2 ? "tokens" : "goods";
            }
            logger.log("tokensList: ", tokensList);
            if (forPiggies) {
                dispatch(
                    coinForPiggiesGetListSuccess({ coinListForPiggies: tokensList })
                );
            } else {
                dispatch(coinGetListSuccess({ coinList: tokensList }));
            }
        } catch (error: any) {
            logger.debug("[coin - coinGetList] something went wrong:", error);
            dispatch(coinCreateFail({ error }));
            return;
        }
    };
};

export const getTokenWithBalanceFM = (
    contractAddress?: string,
    symbol?: string
) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        dispatch(coinGetListReset());
        dispatch(coinGetListStart());
        const currentProfile = getState().user.currentProfile;
        if (currentProfile == null) {
            coinCreateFail({
                error: `Something went wrong, current profile is undefined! ${currentProfile}`,
            });
            return;
        }
        const accountAddress =
            currentProfile.additional_properties?.commonshoodWallet;
        if (accountAddress == null) {
            coinCreateFail({
                error: `Something went wrong, wallet address of current profile is undefined! ${currentProfile}`,
            });
            return;
        }
        try {
            let token: Token = await getTokenWithBalanceFromMetadata(
                accountAddress,
                contractAddress,
                symbol
            );
            token.logo = getIpfsUrl(token.logo);
            token.type = token.decimals == 2 ? "tokens" : "goods";
            logger.log("token: ", token);
            dispatch(coinGetListSuccess({ coinList: [token] }));
        } catch (error: any) {
            logger.debug("[coin - coinGetList] something went wrong:", error);
            dispatch(coinCreateFail({ error }));
        }
    };
};

export const getTokenFromSymbolSlice = (symbol: string) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        const currentProfile = getState().user.currentProfile;
        const ethers = getState().ethers.ethersInstance;

        if (currentProfile == null || ethers == null) {
            console.log(`No currentProfile found`);
            return;
        }
        const accountAddress =
            currentProfile.additional_properties?.commonshoodWallet;
        if (accountAddress == null) {
            console.log(`No accountAddress found`);
            return;
        }
        try {

            const token = await getTokenBySymbol(
                ethers,
                accountAddress,
                symbol
            );
            return token;
        } catch (error: any) {
            logger.debug("[coin - coinGetList] something went wrong:", error);
        }
    };
}

export default coinSlice.reducer;
