import { Typography, createStyles, makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getFromIpfs, getJsonFromResource } from "../../../api/resourceAPI";
import config from "../../../config";
import { useAppSelector } from "../../../store/hooks";
import { getCollectionFromSymbol } from "../../../store/slices/nftSlice";
import { Item } from "../../../types/libofthings.type";
import { getTokenByID } from "../../../api/nftAPI";

const styles = makeStyles({
  imageContainer: {
    position: 'relative',
    display: 'inline-block',
    margin: '10px',
    ['@media (max-width: 600px)']: {
      height: '50%'
    }
  },
  image: {
    maxHeight: '617px',
    '@media (max-width: 600px)': {
      maxHeight: '220px'
    }
  }
})


export const ImageContainer = ({
  item
}: { item: Item }) => {
  const dispatch = useDispatch();
  const [uri, setUri] = useState<any>('');
  const [metadata, setMetadata] = useState<any>(null);
  const [isImageLoad, setIsImageLoad] = useState<boolean>(false);
  const collectionsAndSymbols = useAppSelector(state => state.nft.collectionsBySymbol);
  const useStyles = styles();

  useEffect(() => {
    const collectionAndSymbol = collectionsAndSymbols.filter((collection) => {
      if (collection.symbol.toUpperCase() === item.collectionSymbol.toUpperCase()) {
        return collection;
      }
    })[0];
    if (collectionAndSymbol == null) {
      (async () => { dispatch(getCollectionFromSymbol(item.collectionSymbol)) })()
    } else {
      (async () => {
        const token = await getTokenByID(collectionAndSymbol.address, item.idInCollection.toString());
        setUri(token.tokenUri);
      })()
    }

  }, [collectionsAndSymbols])

  useEffect(() => {
    (async () => {
      if (uri.includes(config.network.ipfs.default_url))
        setMetadata(await getFromIpfs(uri));
      else setMetadata(await getJsonFromResource(uri));
    })()
  }, [uri])

  useEffect(() => {
    if (metadata !== null) {
      if (metadata.image.includes(config.network.ipfs.default_url)) {
        setMetadata({
          ...metadata,
          image: metadata.image.replace(
            config.network.ipfs.default_url,
            config.network.ipfs.gateway_url
          ),
        });
      }
      if (!metadata.image.includes(config.network.ipfs.default_url)) {
        let tempImage = new Image();
        tempImage.onload = () => {
          setIsImageLoad(true);
        };
        tempImage.src = metadata.image;
      }
    }
  }, [metadata]);

  return (
    <div className={useStyles.imageContainer} style={{
      width: '95%',
    }}>
      {
        isImageLoad ? (
          <img src={metadata.image} className={useStyles.image} alt="placeholder" width={'100%'} style={{
            borderRadius: '18px',
          }} />
        ) : (<></>)
      }

    </div>
  )
}