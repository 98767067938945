import { useContext, useEffect, useState } from "react";
import { Item } from "../../../types/libofthings.type";
import { LibraryOfThingsContractContext } from "../../../providers/LibraryOfThingsContractProvider";
import { Box, Grid, Menu, MenuItem, Typography, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import { Title } from "../BasicComponents/Title";
import { ListOfCards } from "../NewArrivals/ListOfCards";
import { useAppSelector } from "../../../store/hooks";
import React from "react";
import { BlackButton } from "../../../components/Buttons/BlackButton";
import { delay } from "../../../utilities/timer/wait";

const useStyles = makeStyles((theme) => ({
    icon: {
        [theme.breakpoints.down('sm')]:
        {
            fontSize: '0.7em'
        }
    },
    emptyList: {
        alignContent: "center"
    },
    menu: {
        width: '300px',
        '& .MuiPaper-root': {
            borderRadius: 20,
            border: 'white',
            borderWidth: 2,
            fontSize: 10,
            borderStyle: 'solid',
            maxWidth: 500
        }
    },
}));

export const ItemsInHome = () => {
    const [items, setItems] = useState<Item[]>([]);
    const { getItems } = useContext(LibraryOfThingsContractContext);
    const categories = useAppSelector(state => state.libOfThings.categories);
    const [category, setCategory] = useState<string>('');
    const isMobile = useMediaQuery('(max-width:600px)');
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const theme = useTheme();
    const classes = useStyles(theme);
    const [filterApplied, setFilterApplied] = useState(false);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        (async () => {
            await getItemWithoutFilters();
        })()
    }, [])

    const getItemWithoutFilters = async () => {
        setFilterApplied(false);
        setCategory('');
        const res = await getItems(undefined, 0, 100);
        setItems(res.items);
    }

    const getItemsByCategory = async (event: React.MouseEvent<HTMLElement>) => {
        const selectedCategory = event.currentTarget.textContent?.trim();

        if (selectedCategory) {
            setFilterApplied(true);
            setCategory(selectedCategory)
            const items = await getItems(selectedCategory, 0, 100);
            if (items.items != null) {
                setItems(items.items);
            }
        }
    }

    return (
        <Box marginBottom={'10em'}>
            <Grid container spacing={2} style={{ paddingTop: isMobile ? '0' : '3rem' }}>
                <Grid item xs={9}>
                    <Title title={'Biblioteca delle cose'} />
                </Grid>
                <Grid item xs={3} style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '2em' }}>
                    <img
                        onClick={handleClick}
                        src='/svgs/filter_icon.svg' width={30} height={30} style={{ cursor: 'pointer' }}
                    />
                    <Menu
                        id="demo-positioned-menu"
                        aria-labelledby="demo-positioned-button"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        className={classes.menu}
                    >
                        <Typography variant="body1" style={{ padding: '16px', color: 'rgba(218, 93, 62, 1)' }}>Filtri</Typography>
                        {
                            categories.map((e) => {
                                if (e.toUpperCase() === category.toUpperCase()) {
                                    return <MenuItem style={{ fontSize: '14px', color: 'red' }} onClick={(e) => getItemsByCategory(e)}>{e}</MenuItem>
                                } else {
                                    return <MenuItem style={{ fontSize: '14px' }} onClick={(e) => getItemsByCategory(e)}>{e}</MenuItem>
                                }
                            })
                        }
                        {
                            filterApplied && (
                                <Box
                                    padding={'16px'}
                                >
                                    <BlackButton
                                        text="Cancella filtro"
                                        onClick={getItemWithoutFilters}
                                    />
                                </Box>
                            )
                        }
                    </Menu>
                </Grid>
                <Grid item xs={12}>
                    <ListOfCards
                        callback={async () => {
                            await delay(1000); 
                            getItemWithoutFilters()
                        }}
                        items={items} />
                </Grid>
            </Grid>
        </Box>
    );
}