import axios from 'axios';
import config from '../../config';
import {logger} from '../logger/logger';

const instance = axios.create(
    // CHANGED FOR NOT THEGRAPH
    {baseURL: config.network.notifications.url}
);

export const notificationsAPILoadToken = () => {
    const token = localStorage.getItem('token');
    if (token == null) {
        logger.debug('token still null ', token);
        setTimeout(notificationsAPILoadToken, 250);
    } else {
        logger.debug('updating axios TRANSACTIONSAPI with token: ', token);
        instance.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        instance.defaults.headers.common["Realm"] = config.realm.REALM_METADATA;
    }
};

instance.interceptors.response.use(undefined, err => {
    const error = err.response;

    // if error is 401
    if (error && error.status === 401 &&
        error.config &&
        !error.config.__isRetryRequest
    ) {
        window.location.assign("/logout");
    }
});

export default instance
