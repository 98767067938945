import { createContext, useState, useEffect } from "react";

// Create the context
export const LocalStorageContext = createContext<{
  itemsSavedInLS: string,
  updateLocalStorageItems: (key: string, newValue: any) => void
}>({
  itemsSavedInLS: '',
  updateLocalStorageItems: (key: string, newValue: any) => { }
});

// Context Provider component
export const LocalStorageProvider = (props: any) => {
  const [itemsSavedInLS, setitemsSavedInLS] = useState(
    localStorage.getItem("savedItems") || ""
  );

  // Function to update localStorage and the context state
  const updateLocalStorageItems = (key: string, newValue: any): void => {
    console.log(newValue);
    localStorage.setItem(key, newValue);
    setitemsSavedInLS(localStorage.getItem(key) || '');
  };

  useEffect(() => {
    const handleStorageChange = (event: any) => {
      if (event.key === "savedItems") {
        setitemsSavedInLS(event.newValue);
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <LocalStorageContext.Provider
      value={{ itemsSavedInLS, updateLocalStorageItems }}
    >
      {props.children}
    </LocalStorageContext.Provider>
  );
};
