import { Box, Grid, Typography, makeStyles, useMediaQuery } from "@material-ui/core";
import { Preorder, PreorderStatus } from "../../../../types/calendar.type";
import { ItemImage } from "../../MyInventory/MyItems/ItemImage";
import { useContext, useEffect, useState } from "react";
import { Item } from "../../../../types/libofthings.type";
import { getItemInfoByID } from "../../../../api/libOfThingsAPI";
import { getCollectionBySymbol } from "../../../../api/nftAPI";
import { ButtonsToHandleActivePreorders } from "../Active/ButtonsToHandleActivePreorders";
import { useTranslation } from "react-i18next";
import { FirstLifeContextImpl } from "../../../../providers/FirstLifeProvider";
import PreorderStepper from "../Steppers/PreorderStepper";
import { BlackButton } from "../../../../components/Buttons/BlackButton";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { cancelPreorder } from "../../../../store/slices/calendarSlice";
import toast from "react-hot-toast";

const useStyles = makeStyles({
    preorder: {
        background: 'white',
        width: '100%',
        borderRadius: '18px',
    },
    centerOnlyInMobile: {
        '@media (max-width:600px)': {
            textAlign: 'center',
        }
    },
    requestDateContainer: {
        '@media (max-width:600px)': {
            textAlign: 'center',
        },
        '@media (min-width:600px)': {
            textAlign: 'right',
        }
    },
    hideInMobile: {
        '@media (max-width:600px)': {
            display: 'none',
        }
    },
    displayOnlyInMobile: {
        '@media (min-width:600px)': {
            display: 'none',
        }
    }
});

export const MyRequestsPreorderInList = ({
    preorder,
    textUnderStepper,
    callback
}: {
    preorder: Preorder,
    textUnderStepper: string,
    callback?: () => void
}) => {
    const dispatch = useAppDispatch();
    const loading = useAppSelector(state => state.calendar.loading);
    const [item, setItem] = useState<Item>();
    const [startDate, setStartDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');
    const [requestDate, setRequestDate] = useState<string>('');
    const [collectionAddress, setCollectionAddress] = useState<string>('');
    const [status, setStatus] = useState<number>(0);
    const styles = useStyles();
    const useMobileQuery = useMediaQuery('(max-width: 600px)');
    const { t } = useTranslation('LibOfThings');
    const [lenderEmail, setLenderEmail] = useState<string>('');
    const { getUserByAddress } = useContext(FirstLifeContextImpl);
    const [cancelled, setCancelled] = useState(false);

    useEffect(() => {
        (async () => {
            const item = await getItemInfoByID(preorder.itemID);
            if (item != null) {
                const collection = await getCollectionBySymbol(item.collectionSymbol);
                setCollectionAddress(collection.contractAddress);
            }
            setItem(item);
            await retrieveLenderEmail();
        })();
    }, [preorder.itemID]);

    const retrieveLenderEmail = async () => {
        if (preorder.lender != null) {
            const user = await getUserByAddress(preorder.lender);
            if (user != null) {
                setLenderEmail(user.email);
            }
        }
    };

    useEffect(() => {
        if (preorder != null) {
            const currentLocale = navigator.language;
            const dateOptions: Intl.DateTimeFormatOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
            const startDate = new Date(preorder.startDate * 1000);
            const endDate = new Date(preorder.endDate * 1000);
            const requestDate = new Date(preorder.timestamp * 1000);

            setStartDate(startDate.toLocaleString(currentLocale, dateOptions));
            setEndDate(endDate.toLocaleString(currentLocale, dateOptions));
            setRequestDate(requestDate.toLocaleString(currentLocale, dateOptions));
            handleStatus(preorder.status);
        }
    }, [preorder]);

    const handleStatus = (status: string) => {
        const normalizedStatus = status.toUpperCase();
        switch (normalizedStatus) {
            case 'PENDING':
                setStatus(PreorderStatus.Pending);
                break;
            case 'ACCEPTED':
                setStatus(PreorderStatus.Accepted);
                break;
            case 'STARTED':
                setStatus(PreorderStatus.Started);
                break;
            case 'ENDED':
                setStatus(PreorderStatus.Ended);
                break;
            case 'CANCELLED':
                setStatus(PreorderStatus.Cancelled);
                break;
            case 'DENIED':
                setStatus(PreorderStatus.Denied);
                break;
            default:
                break;
        }
    };

    const handleCancelPreorder = async () => {
        setCancelled(true);

        await toast.promise(dispatch(cancelPreorder(preorder.preorderID, false)), {
            loading: 'Cancellando la richiesta',
            success: 'Richiesta cancellata',
            error: 'Qualcosa è andato storto'
        });

        if (callback) callback();
    };

    const isStartDatePast = () => {
        const currentTimestamp = Math.floor((new Date()).getTime() / 1000);
        return currentTimestamp >= preorder.startDate;
    };

    return (
        <Grid container className={styles.preorder} spacing={1}>
            {preorder != null && item != null && preorder.startDate != null && (
                <>
                    <Grid item xs={4} sm={2} style={{ paddingLeft: '20px' }}>
                        <ItemImage
                            collectionSymbol={item.collectionSymbol}
                            id={item.idInCollection}
                            option={undefined}
                            respectRatio={true}
                            style={{ display: 'block', margin: 'auto' }}
                        />
                        <Grid container>
                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                                <Typography variant="body1" style={{ fontWeight: 'bold' }}>{item.name}</Typography>
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                                <Typography variant="body1" style={{ fontWeight: 'bold', color: 'rgba(218, 93, 62, 1)' }}>{item.category[0].concat(item.category.slice(1).toLowerCase())}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={1} className={styles.hideInMobile}>
                        <img height={'90%'} src="/svgs/orange_line_desktop.svg" alt="Orange Line" style={{ display: 'block', margin: 'auto' }} />
                    </Grid>
                    <Grid item xs={8} sm={9}>
                        <Grid container spacing={1} style={{ marginTop: '1em' }}>
                            <Grid item xs={12} sm={6} className={styles.centerOnlyInMobile}>
                                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                                    {t('requestNoun')} {preorder.preorderID.slice(0, 3).concat(preorder.preorderID.slice(-4)).toLocaleUpperCase()}
                                </Typography>
                            </Grid>
                            {!useMobileQuery && (
                                <Grid item xs={12} sm={6} style={{ padding: '16px 30px', textAlign: 'right' }}>
                                    <Typography variant="body2">{lenderEmail}</Typography>
                                </Grid>
                            )}
                            <Grid container item xs={12}>
                                <Grid item xs={12}>
                                    {useMobileQuery ? (
                                        <Typography variant="body2" style={{ fontWeight: 'lighter', textAlign: 'center' }}>
                                            Richiesto il <strong>{requestDate}</strong><br />
                                            per il periodo dal<br />
                                            <strong>{startDate}</strong> al <strong>{endDate}</strong>
                                        </Typography>
                                    ) : (
                                        <Typography variant="body1" style={{ fontWeight: 'lighter' }}>
                                            Richiesto il <strong>{requestDate}</strong> per il periodo dal <strong>{startDate}</strong> al <strong>{endDate}</strong>
                                        </Typography>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid item xs={12} className={`${styles.centerOnlyInMobile} ${styles.hideInMobile}`} style={{ marginTop: '1em' }}>
                                <PreorderStepper preorder={preorder} lender={true} />
                            </Grid>
                            {status === PreorderStatus.Accepted && !useMobileQuery && !cancelled && isStartDatePast() && (
                                <Grid item xs={4} className={styles.hideInMobile} style={{ width: '100%', textAlign: 'center', paddingLeft: '5%', paddingRight: '5%' }}>
                                    <Typography style={{ marginRight: '1em' }} variant="subtitle2">Il/la proprietario/a è in ritardo con la consegna, in questo caso hai la possibilità di cancellare la richiesta.</Typography>
                                    <BlackButton disabled={loading} text="Cancella richiesta" onClick={handleCancelPreorder} />
                                </Grid>
                            )}
                            {status !== PreorderStatus.Pending && !useMobileQuery && (
                                <Grid item md={4} className={styles.hideInMobile} style={{ width: '100%' }}>
                                    <Grid container justifyContent="center">
                                        <ButtonsToHandleActivePreorders preorder={preorder} item={item} nftTemplate={collectionAddress} />
                                    </Grid>
                                </Grid>
                            )}
                            {status === PreorderStatus.Pending && !useMobileQuery && (
                                <Grid item md={4} className={styles.hideInMobile} style={{ width: '100%' }}>
                                    <Grid container style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <Typography style={{ marginRight: '1em' }} variant="subtitle2">A breve il/la proprietario/a ti ricontatterà!</Typography>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={styles.displayOnlyInMobile} style={{ textAlign: 'center' }}>
                        <PreorderStepper preorder={preorder} lender={true} />
                    </Grid>

                    <Grid item style={{ textAlign: 'center', marginLeft: 'auto', marginRight: 'auto', width: '16.5em' }} className={styles.displayOnlyInMobile}>
                        <Typography style={{ paddingTop: '1em' }}>{textUnderStepper}</Typography>
                        {useMobileQuery && (
                            <Grid item xs={12} style={{ textAlign: 'center', paddingTop: '1em' }}>
                                <Typography variant="body1" style={{ fontWeight: 'bold' }}>{lenderEmail}</Typography>
                            </Grid>
                        )}
                        <ButtonsToHandleActivePreorders preorder={preorder} item={item} nftTemplate={collectionAddress} />
                    </Grid>

                    {status === PreorderStatus.Pending && useMobileQuery && (
                        <>
                            <Grid item xs={12} style={{ textAlign: 'center', marginBottom: '0.5em' }}>
                                <Typography variant="subtitle2">Inviata</Typography>
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: 'center', marginBottom: '1em' }}>
                                <Typography variant="subtitle2">A breve il/la proprietario/a ti ricontatterà!</Typography>
                            </Grid>
                        </>
                    )}
                    {status === PreorderStatus.Accepted && useMobileQuery && !cancelled && isStartDatePast() && (
                        <Grid item xs={12} style={{ width: '100%', textAlign: 'center', paddingLeft: '20%', paddingRight: '20%', marginBottom: '0.5em' }}>
                            <Typography style={{ marginRight: '1em', marginBottom: '1em' }} variant="subtitle2">Il/la proprietario/a è in ritardo con la consegna, in questo caso hai la possibilità di cancellare la richiesta.</Typography>
                            <BlackButton
                                disabled={loading}
                                text="Cancella richiesta" onClick={handleCancelPreorder} style={{ fontSize: '15px', color: 'white' }} />
                        </Grid>
                    )}
                </>
            )
            }
        </Grid >
    );
};
