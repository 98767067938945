import { get } from "https";
import config from "../config";
import {
  CollectionCreateDataApi,
  collectionProps,
  NftCreateDataApi,
  typesAllowed,
} from "../types/nft.types";
import axios from "../utilities/backend/axios-metadata";
import { logger } from "../utilities/logger/logger";
import { ethers, utils, Wallet } from "ethers";

const getNftTemplateInstance = (ethersInstance: Wallet, nftAddress: string) => {
  const abi_template = new utils.Interface(config.smartContracts.NFT_TMPLT_ABI)
  return new ethers.Contract(nftAddress, abi_template, ethersInstance)
}

export const getNftFactoryInstance = (ethersInstance: Wallet) => {
  const abi_factory = new utils.Interface(config.smartContracts.NFT_FCTRY_ABI)
  return new ethers.Contract(
    config.smartContracts.NFT_FCTRY_ADDR,
    abi_factory,
    ethersInstance
  )
}

export const increaseAllowance = async (nftTemplateAddress: string, addressToAllow: string, tokenId: number, ethers: Wallet, from: string) => {
  const nftTemplateInstance = getNftTemplateInstance(ethers, nftTemplateAddress);
  let response = await nftTemplateInstance.approve(addressToAllow, tokenId);
  response = await response.wait();

  return response;
}

export const createNftCollection = async (
  ethers: Wallet,
  accountAddress: string,
  collectionData: CollectionCreateDataApi
) => {
  const { name, symbol, collectionURI } = collectionData;

  const nftFactoryInstance = getNftFactoryInstance(ethers);
  console.log(nftFactoryInstance);
  let response = await nftFactoryInstance.createNftCollection(name, symbol, collectionURI, typesAllowed.artistic)
  response = await response.wait()
  logger.info("symbol: " + symbol);
  logger.info(response);
  return response;
};

export const createNft = async (
  ethers: Wallet,
  accountAddress: string,
  nftData: NftCreateDataApi
) => {
  console.log('in createNft function')
  const { tokenUri, collectionAddress, numbersNft, isTransferable } = nftData
  const nftFactoryInstance = getNftFactoryInstance(ethers)
  let response
  let nftCreated = 0
  let isOkay = true
  let gasAmount = 0
  let nftxTransaction = 0
  while (nftCreated < numbersNft) {
    const block = await ethers.provider.getBlock('latest')
    const nftLeft = numbersNft - nftCreated
    console.log(`Nfts letf to create: ${nftLeft}`);
    if (isOkay) {
      gasAmount = await nftFactoryInstance.estimateGas
        .createNftForCollection(
          collectionAddress,
          tokenUri,
          1,
          isTransferable,
          typesAllowed.artistic
        )
        .then((gasAmount: any) => {
          logger.info('gasAmount for 1: ', gasAmount)
          return gasAmount
        })
        .catch((error: any) => {
          logger.info('error: ', error)
        })
      nftxTransaction = Math.trunc(Number(block.gasLimit) / gasAmount)
    } else {
      nftxTransaction--
    }
    console.log(`FLAG IN NFTAPI`);
    gasAmount = await nftFactoryInstance.estimateGas
      .createNftForCollection(
        collectionAddress,
        tokenUri,
        nftxTransaction < nftLeft ? nftxTransaction : nftLeft,
        isTransferable,
        typesAllowed.artistic
      )
      .then((gasAmount: any) => {
        logger.info(
          'gasAmount for ' +
          (nftxTransaction < nftLeft
            ? nftxTransaction
            : nftLeft) +
          ': ' +
          gasAmount
        )
        isOkay = true
        return gasAmount
      })
      .catch((error: any) => {
        logger.info('error: ', error)
        isOkay = false
      })
    if (nftxTransaction < nftLeft) {
      response = await nftFactoryInstance.createNftForCollection(
        collectionAddress,
        tokenUri,
        nftxTransaction,
        isTransferable,
        nftData.type
      )
      response = await response.wait()
      logger.info(response)
      nftCreated += nftxTransaction
    } else {
      response = await nftFactoryInstance.createNftForCollection(
        collectionAddress,
        tokenUri,
        nftLeft,
        isTransferable,
        nftData.type
      )
      response = await response.wait()
      logger.info(response)
      nftCreated += nftLeft
    }
  }
  return response
};

export const isCollection = async (
  ethers: Wallet,
  accountAddress: string,
  collectionAddress: string
) => {
  try {
    console.log(`Checking if ${collectionAddress} is a collection`);
    const nftFactoryInstance = getNftFactoryInstance(ethers);
    console.log(`flag 2: ${collectionAddress}`);
    const response = await nftFactoryInstance.isCollection(collectionAddress);
    console.log(`flag 3`);
    return response;
  } catch (error) {
    console.log(error);
  }
  return false;
};

export const isCreatedByMe = async (
  ethers: Wallet,
  accountAddress: string,
  collectionAddress: string
) => {
  const nftFactoryInstance = getNftFactoryInstance(ethers);
  const response = await nftFactoryInstance.isCreatedByMe(collectionAddress)
  return response;
};

export const getCollectionOwned = async (accountAddress: string) => {
  const url = "/nft/getCollections";
  const first = 10;
  const params = new URLSearchParams({
    owner: accountAddress,
    skip: "0",
    first: first.toString(),
  });
  const collectionList = await axios.get(url, { params });
  return collectionList.data.nfts;
};
export const getCollection = async (
  ethers: Wallet,
  accountAddress: string,
  contractAddress: string
): Promise<collectionProps> => {
  const url = "/nft/getCollection";
  const params = new URLSearchParams({
    contractAddress: contractAddress,
    owner: accountAddress,
  });
  const collection = await axios.get(url, { params });
  return collection.data.collection;
};
export const getNftsOwned = async (
  owner: string,
  sortBy: string,
  page: string
) => {
  const nfts = await getNfts(owner, undefined, sortBy, page + "");
  return nfts;
};

export const getCollectionBySymbol = async (symbol: string) => {
  const url = "/nft/getCollectionFromSymbol";
  const params = new URLSearchParams({
    symbol: symbol,
  });
  const collection = await axios.get(url, { params });
  return collection.data.collection;
}

export const getNftsFromCollection = async (
  accountAddress: string,
  contractAddress: string,
  page: number,
  sortBy: string
) => {
  const nfts = await getNfts(
    accountAddress,
    contractAddress,
    sortBy,
    page + ""
  );
  return nfts;
};

export const getNfts = async (
  accountAddress: string,
  contractAddress: string | undefined,
  sortBy: string,
  page: string
) => {
  let nfts = [];
  const first = 10;
  try {
    let url = `/nft/getNfts?owner=${accountAddress}&sortBy=${sortBy}&skip=${parseInt(page) * first}&first=${first}`;
    if (contractAddress) {
      url += `&contractAddress=${contractAddress}`;
    }
    const nftList = await axios.get(url);
    nfts = nftList.data.nfts;
  } catch (error) {
    console.log(error);
  }
  return nfts;
};

export const sendNFT = async (
  ethers: Wallet,
  accountAddress: string,
  to: string,
  nftContract: string,
  tokenId: string
) => {
  const nftTemplateInstance = getNftTemplateInstance(ethers, nftContract);
  let transfer = await nftTemplateInstance.safeTransferFrom(accountAddress, to, tokenId)
  transfer = await transfer.wait();
};

export const isOwner = async (
  ethers: Wallet,
  accountAddress: string,
  nftContract: string,
  tokenId: string
) => {
  const nftTemplateInstance = getNftTemplateInstance(ethers, nftContract);
  const owner = await nftTemplateInstance.ownerOf(tokenId)

  return owner === accountAddress;
};
export const isAllowed = async (
  ethers: Wallet,
  templateAddress: string,
  accountAddress: string,
  tokenId: number,
  from: string
) => {
  const nftTemplateInstance = getNftTemplateInstance(ethers, templateAddress);
  const approvedAddress = await nftTemplateInstance.getApproved(tokenId);
  return approvedAddress === accountAddress;
}

export const getURIByID = async (
  ethers: Wallet,
  tokenId: number,
  contractAddress: string,
  from: string
) => {
  const nftTemplateInstance = getNftTemplateInstance(ethers, contractAddress);
  const uri = await nftTemplateInstance.tokenURI(tokenId)
  return uri;
};

export const getAddressFromSymbolAPI = async (symbol: string) => {
  const url = `nft/getCollectionFromSymbol?symbol=${symbol}`;
  const collection = await axios.get(url);
  if (collection == null)
    throw new Error(`Collection information null in getAddressFromSymbol`);
  return collection.data.collection.contractAddress;
};

export const getTokenByID = async (collectionAddress: string, tokenID: string) => {
  const url = `nft/${collectionAddress}/${tokenID}`;
  const token = await axios.get(url);
  if (token == null)
    throw new Error(`Token information null in getTokenByID`);
  return token.data.nft;
}
