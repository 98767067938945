export const getBase64Image = (img: HTMLImageElement) => {
  var canvas = document.createElement("canvas");
  canvas.width = img.width;
  canvas.height = img.height;

  var ctx = canvas.getContext("2d");
  if (ctx)
    ctx.drawImage(img, 0, 0);

  var dataURL = canvas.toDataURL("image/png");

  return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
}

export const getBase64ImageFromFile = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      const dataURL = reader.result as string;
      const base64Image = dataURL.replace(/^data:image\/(png|jpg|jpeg|gif);base64,/, "");
      resolve(base64Image);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
}

export const getImageFromBase64String = (base64String: string, mime: string) => {
  let url = `data:${mime};base64,${base64String}`
  return url;
}

export const base64ToFile = (base64String: string, fileName: string, mimeType: string): File => {
  const byteString = atob(base64String.split(',')[1]);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const uintArray = new Uint8Array(arrayBuffer);

  for (let i = 0; i < byteString.length; i++) {
    uintArray[i] = byteString.charCodeAt(i);
  }

  const blob = new Blob([uintArray], { type: mimeType });
  return new File([blob], fileName, { type: mimeType });
};