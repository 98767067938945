import { FormikProps } from "formik"
import { ExchangeInfoInForm } from "./ExchangeFormContainer"
import { Box, FormControl, Grid, Input, InputAdornment, Theme, Typography, createStyles, makeStyles, useMediaQuery } from "@material-ui/core"
import { OrangeLine } from "../../Wallet/Coupon/CreationForm/OrangeLine"
import SearchIcon from '@material-ui/icons/Search';
import { useContext, useEffect, useState } from "react";
import { getTokensOwnedFromMetadata } from "../../../api/coinAPI";
import { CouponLogo } from "../../Wallet/Coupon/MyCoupons/CouponLogo";
import config from "../../../config";
import { InputField } from "../../../components/Input/InputField";
import { ExchangeFormContext } from "../../../providers/ExchangeFormCreationProvider";
import { BlackButton } from "../../../components/Buttons/BlackButton";
import { TransparentButton } from "../../../components/Buttons/TransparentButton";
import { useAppSelector } from "../../../store/hooks";

const useStyle = makeStyles({
    container: {
        '@media (max-width:600px)': {
        }
    },
    descriptionContainer: {
        '@media (min-width:600px)': {
            marginTop: '4em'
        }
    }
})

export const ThirdStep = ({ formik }: {
    formik: FormikProps<ExchangeInfoInForm>
}) => {
    const [coupons, setCoupons] = useState<any[]>([]);
    const [couponsToShow, setCouponsToShow] = useState<any[]>([]);
    const account = useAppSelector(state => state.user.currentProfile);

    useEffect(() => {
        const getMyCoupons = async () => {
            const address = account?.additional_properties?.commonshoodWallet;
            if (address != null) {
                const tokens = await getTokensOwnedFromMetadata(
                    address,
                    'goods',
                    1,
                    true
                );
                console.log(tokens);
                setCoupons(tokens);
                setCouponsToShow(tokens);
            }
        }

        getMyCoupons();
    }, [])

    const updateLogoUrl = (tokens: any[]) => {
        for (let i = 0; i < tokens.length; i++) {
            tokens[i].logo = tokens[i].logo.replace(config.network.ipfs.default_url, config.network.ipfs.gateway_url);
        }
    }

    const onChangeSearchBar = (event: React.ChangeEvent<HTMLInputElement>) => {
        filterCoupon(event.target.value);
    }

    const filterCoupon = (search: string) => {
        const filteredCoupons = coupons.filter(coupon => {
            return coupon.symbol.toLowerCase().includes(search.toLowerCase());
        });
        setCouponsToShow(filteredCoupons);
    }

    const onClickCoupon = (coupon: any) => {
        setCouponsToShow([coupon]);
    }

    return (
        <>
            <Grid container className={useStyle().container}>
                <Description />
                <Grid item xs={12} sm={8} style={{ marginTop: '4em' }}>
                    <SearchBar formik={formik} onChange={onChangeSearchBar} />
                    <CouponList formik={formik} coupons={couponsToShow} onClickCoupon={onClickCoupon} />
                    <CouponsRequested formik={formik} />
                </Grid>
            </Grid>
            <ContinueButton formik={formik} />
        </>
    )
}

const Description = () => {
    const isMobile = useMediaQuery('(max-width:600px)');

    return (
        <>
            <Grid item xs={12} sm={2} justifyContent="center" direction="column" className={useStyle().descriptionContainer}>
                <Box textAlign={isMobile ? 'center' : 'right'}>
                    <Typography variant='h6'>
                        Quale coupon vuoi offrire?
                    </Typography>
                    {
                        isMobile && (
                            <Grid item xs={12} sm={1}>
                                <OrangeLine />
                            </Grid>
                        )
                    }
                    {
                        !isMobile && (
                            <Typography variant={'h6'} style={{ top: '8em', position: 'relative' }}>
                                Quantità?
                            </Typography>
                        )
                    }
                </Box>
            </Grid>
            {
                !isMobile && (
                    <Grid item xs={12} sm={1}>
                        <OrangeLine />
                    </Grid>
                )
            }
        </>
    )
}

const useStylesSearchBar = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            border: '1px solid rgba(24, 27, 29, 1)',
            borderRadius: '10px',
            minWidth: 300,
            background: 'white',
            padding: 4,
            '@media (max-width: 600px)': {
                width: '90%',
            }
        },
        searchBarContainer: {
            '@media (max-width: 600px)': {
                justifyContent: "center"
            }
        }
    }),
);

const SearchBar = ({ formik, onChange }: {
    formik: FormikProps<ExchangeInfoInForm>,
    onChange: any
}) => {
    const classes = useStylesSearchBar();

    return (
        <Grid container item xs={12} sm={3} className={useStylesSearchBar().searchBarContainer}>
            <FormControl className={classes.formControl} >
                <Input
                    style={{ zIndex: 10 }}
                    inputProps={{ disableUnderline: true }}
                    onChange={onChange}
                    placeholder='Ricerca coupon...'
                    disableUnderline={true}
                    id="input-with-icon-adornment"
                    endAdornment={
                        <InputAdornment position="start">
                            <SearchIcon
                                style={{ cursor: 'pointer' }}
                            />
                        </InputAdornment>
                    }
                />
            </FormControl>
        </Grid>
    )
}

const useStyles = makeStyles({
    couponItem: {
        display: 'inline-block',
        marginRight: '1em', // Adjust spacing between items as needed
    },
    couponContainer: {
        maxWidth: '100%',
        height: 'auto',
        marginBottom: '16px'
    },
    couponsListContainer: {
        overflowX: 'auto',  // Allow horizontal scrolling
        whiteSpace: 'nowrap',  // Prevent wrapping of items
        marginTop: '2em',
        width: '100%',
    },
});

const CouponList = ({ formik, coupons, onClickCoupon }: {
    formik: FormikProps<ExchangeInfoInForm>,
    coupons: any[],
    onClickCoupon: (coupon: any) => void
}
) => {
    const classes = useStyles();
    const couponsToDisplayDesktop = 15;
    const couponsToDisplayMobile = 8;
    const [selectedCoupon, setSelectedCoupon] = useState<any>({});

    const isMobile = useMediaQuery('(max-width:600px)');

    useEffect(() => {
        if (coupons.length > 0) {
            formik.setFieldValue('symbolOfCouponRequested', coupons[0].symbol);
            formik.setFieldValue('couponSrc', coupons[0].logo);
            formik.setFieldValue('couponAddress', coupons[0].address);
        }
    }, [coupons])

    const onCouponClickHandler = (coupon: any) => {
        setSelectedCoupon(coupon);
        onClickCoupon(coupon);
    }




    return (
        <>
            {
                isMobile ? (
                    <Grid container style={{ marginTop: '3em' }}>
                        {coupons.slice(0, couponsToDisplayMobile).map(coupon => (
                            <Grid item xs={3} style={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => {
                                onCouponClickHandler(coupon);
                            }}>
                                <div key={coupon.id} className={classes.couponItem}>
                                    <div className={classes.couponContainer} >
                                        <CouponLogo logo={coupon.logo} selected={selectedCoupon.symbol === coupon.symbol} />
                                        <Typography variant='subtitle1' >
                                            {coupon.symbol}
                                        </Typography>
                                    </div>
                                </div>
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    <div className={classes.couponsListContainer}>
                        {coupons.slice(0, couponsToDisplayDesktop).map(coupon => (
                            <div key={coupon.id} className={classes.couponItem} onClick={() => {
                                onCouponClickHandler(coupon);
                            }} style={{ cursor: 'pointer' }}>
                                <div className={classes.couponContainer}>
                                    <CouponLogo logo={coupon.logo} selected={selectedCoupon.symbol === coupon.symbol} />
                                    <Typography variant='subtitle1' >
                                        {coupon.symbol}
                                    </Typography>
                                </div>
                            </div>
                        ))}
                    </div>
                )
            }
        </>

    );
}

const couponsRequestStyle = makeStyles({
    textField: {
        '@media (max-width:600px)': {
            maxWidth: '230px'
        },
        '@media (min-width:600px)': {
            maxWidth: '400px'
        },
        '@media (min-width:600px) and (max-width: 860px)': {
            maxWidth: '300px'
        }
    },
    quantityContainer: {
        '@media (min-width:600px)': {
            display: 'none'
        }
    },
    container: {
        marginTop: '3em',
        '@media (max-width:600px)': {
            marginLeft: '1em'
        }
    }
})

const CouponsRequested = ({ formik }: { formik: FormikProps<ExchangeInfoInForm> }) => {
    const classes = couponsRequestStyle();

    const onQntChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        formik.setFieldValue('couponsRequestedAmount', event.target.value);
    }

    return (
        <Grid container className={classes.container}>
            <Grid item xs={12} style={{ marginTop: '7px' }} className={classes.quantityContainer}>
                <Typography variant='body1'>Quantità</Typography>
            </Grid>
            <Grid item xs={7} sm={6} style={{ textAlign: 'center', maxWidth: '100px' }} className={classes.textField}>
                <InputField
                    type={'number'}
                    value={formik.values.couponsRequestedAmount.toString()}
                    placeholder='_ _ _'
                    variant='outlined'
                    onChange={onQntChange}
                    style={{ textAlign: 'center' }}
                    min={1}
                    max={999}
                />
            </Grid>
        </Grid>
    )
}

const ContinueButton = ({ formik }: { formik: FormikProps<ExchangeInfoInForm> }) => {
    const { setStep } = useContext(ExchangeFormContext);

    return (
        <Grid container style={{ marginTop: '1em' }} spacing={2}>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
                <BlackButton
                    width={227}
                    onClick={() => { setStep(3) }}
                    text={'Vai al riepilogo'}
                    disabled={formik.values.couponsRequestedAmount === 0}
                />
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
                <TransparentButton
                    width={227}
                    style={{
                        color: 'rgba(218, 93, 62, 1)',
                        border: 'none'
                    }}
                    onClick={() => formik.resetForm()}
                    text={'Annulla'}
                />
            </Grid>
        </Grid>
    )
}