import { Box, Grid, Link, makeStyles, Typography, useMediaQuery } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { useContext, useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import { getCollectionFromSymbol, getURIByIDSlice } from "../../../store/slices/nftSlice"
import { Item } from "../../../types/libofthings.type"
import { backgroundCardStyle } from "../Styles/Backgrounds.style"
import { ImageContainer } from "./ImageContainer"
import { ItemCalendar } from "./ItemCalendar"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { FirstLifeContextImpl } from "../../../providers/FirstLifeProvider"
const useStyles = makeStyles({
    subtitle: {
        color: 'rgba(218, 93, 62, 1)',
        weight: 400,
        size: '16px',
        lineHeight: '20.24px',
        cursor: 'pointer',
        '&:hover': {
            color: 'rgba(235, 176, 76, 1)'
        }
    },
    label: {
        color: 'rgba(129, 129, 129, 1)',
        weight: 400,
        size: '10px',
        lineHeight: '12.65px'
    },
    itemInformationContainer: {
        paddingLeft: '2em',
        paddingRight: '2em',
        paddingBottom: '2em',
        '@media (min-width: 600px)': {
            paddingTop: '3em'
        }
    },
    calendarContainer: {
        ['@media (min-width: 600px)']: {
            margin: '2em'
        }
    },
    availability: {
        ['@media (max-width: 600px)']: {
            textAlign: 'center'
        },
        ['@media (min-width: 600px)']: {
            textAlign: 'left'
        }
    }
})

export const ItemInformation = ({ item, summaryOpened, email }: {
    item: Item,
    summaryOpened: boolean,
    email: string
}) => {
    const dispatch = useAppDispatch();
    const collectionsAndSymbols = useAppSelector((state) => state.nft.collectionsBySymbol);
    const isMobile = useMediaQuery('(max-width:600px)');

    useEffect(() => {
        if (item != null) {
            const collectionAndAddress = collectionsAndSymbols.filter((collection) => {
                if (collection.symbol.toUpperCase() === item.collectionSymbol.toUpperCase()) {
                    return collection;
                }
            })[0];
            if (collectionAndAddress == null) {
                (async () => { await dispatch(getCollectionFromSymbol(item.collectionSymbol)) })()
            } else {
                let address = collectionAndAddress.address;
                (async () => { await dispatch(getURIByIDSlice(item.idInCollection, address)) })()
            }
        }

    }, [collectionsAndSymbols])


    return (
        <>
            {
                isMobile ? (
                    <ItemInfoMobile item={item} email={email} summaryOpened={summaryOpened} />
                ) : (
                    <ItemInfoDesktop item={item} email={email} summaryOpened={summaryOpened} />
                )
            }
        </>
    )
}

const ItemInfoMobile = ({ item, email, summaryOpened }: {
    item: Item,
    email: string,
    summaryOpened: boolean
}) => {
    const style = useStyles();
    const [timeOfUpload, setTimeOfUpload] = useState<Date>();
    const dateOptions: Intl.DateTimeFormatOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
    const { t } = useTranslation('LibOfThings');
    const useInformationBox = backgroundCardStyle();
    const history = useHistory();


    const openItemPageOfUser = () => {
        history.push(`/users/${item.owner}/${email}/items`);
    }

    useEffect(() => {
        if (item != null) {
            setTimeOfUpload(new Date(item.dateOfUpload * 1000));
        }
    }, [item])

    return (
        <Grid container className={useInformationBox.root} >
            <Grid item xs={12} style={{
                minHeight: '500px',
                width: '100%',
                margin: '1em'
            }}>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={5} style={{
                        height: '60%',
                    }}>
                        {
                            (item != null) ? (
                                <ImageContainer item={item} />
                            ) : (
                                <Skeleton />
                            )
                        }
                    </Grid>
                    <Grid item xs={12} sm={7} className={style.itemInformationContainer}>
                        <Grid container spacing={1} direction="row">
                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                                <Typography variant="h5" >{item.name}</Typography>
                            </Grid>
                            <Grid item container xs={12}>
                                <Grid item xs={12} style={{ textAlign: 'center' }}>
                                    {
                                        timeOfUpload != null && <b><Typography variant="subtitle2" className={style.label}>
                                            {t('published')} {timeOfUpload.toLocaleString(navigator.language, dateOptions)}
                                        </Typography></b>
                                    }
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2" style={{ textAlign: 'center', margin: '2em' }}>
                                        <b>
                                            <Link className={style.subtitle} onClick={openItemPageOfUser}>
                                                {email}
                                            </Link>
                                        </b>
                                    </Typography>
                                </Grid>
                                <Grid item xs={5} style={{ marginBottom: '1em' }}>
                                    <Typography variant="body1" >
                                        Categoria: <b>{item.category.toLowerCase()[0].toUpperCase() + item.category.toLowerCase().slice(1)}</b>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2">Prestito:
                                        <b>
                                            {
                                                item.requireSupervision ? 'Con supervisione' : 'In autonomia'
                                            }
                                        </b>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2">
                                        Questo oggetto è stato prestato <b>{item.numberOfTimesRequested}</b> volte.
                                    </Typography>
                                </Grid>
                            </Grid>
                            {
                                !summaryOpened && (
                                    <>
                                        <Grid item xs={12} className={style.availability}>
                                            <Typography variant="body1">Disponibilità</Typography>
                                        </Grid>
                                    </>
                                )
                            }
                            <Grid item xs={12} className={style.calendarContainer}>
                                <ItemCalendar item={item} summaryOpened={summaryOpened} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

const ItemInfoDesktop = ({ item, email, summaryOpened }: { item: Item, email: string, summaryOpened: boolean }) => {
    const style = useStyles();
    const [timeOfUpload, setTimeOfUpload] = useState<Date>();
    const dateOptions: Intl.DateTimeFormatOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
    const { t } = useTranslation('LibOfThings');
    const useInformationBox = backgroundCardStyle();
    const history = useHistory();

    const openItemPageOfUser = () => {
        history.push(`/users/${item.owner}/${email}/items`);
    }

    useEffect(() => {
        if (item != null) {
            setTimeOfUpload(new Date(item.dateOfUpload * 1000));
        }
    }, [item])

    return (
        <Grid container className={useInformationBox.root} >
            <Grid item xs={12} style={{
                minHeight: '500px',
                width: '100%',
                margin: '1em'
            }}>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={5} style={{
                        height: '100%',
                    }}>
                        {
                            (item != null) ? (
                                <ImageContainer item={item} />
                            ) : (
                                <Skeleton />
                            )
                        }
                    </Grid>
                    <Grid item xs={12} sm={7} className={style.itemInformationContainer}>
                        <Grid container spacing={1} direction="row">
                            <Grid item xs={12}>
                                <Typography variant="h5" >{item.name}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1" className={style.subtitle} style={{ marginTop: '0.5em' }}>
                                    {item.category.toLowerCase()[0].toUpperCase() + item.category.toLowerCase().slice(1)}
                                </Typography>
                                {
                                    timeOfUpload != null && <b><Typography variant="subtitle2" className={style.label}>
                                        {t('published')} {timeOfUpload.toLocaleString(navigator.language, dateOptions)}
                                    </Typography></b>
                                }
                            </Grid>
                            <Grid item xs={12} style={{
                                textAlign: 'right'
                            }}>
                                <Typography variant="body1" >{email}</Typography>
                                <Typography variant="subtitle2">
                                    <b><Link className={style.subtitle} onClick={openItemPageOfUser}>
                                        Vedi tutti i suoi oggetti caricati
                                    </Link></b>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2">Prestito:
                                    <b>
                                        {
                                            item.requireSupervision ? 'Con supervisione' : 'In autonomia'
                                        }
                                    </b>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2">
                                    Questo oggetto è stato prestato <b>{item.numberOfTimesRequested}</b> volte.
                                </Typography>
                            </Grid>
                            {
                                !summaryOpened && (
                                    <>
                                        <Grid item xs={12} style={{ marginTop: '3em' }} className={style.availability}>
                                            <Typography variant="body1">Disponibilità</Typography>
                                        </Grid>
                                    </>
                                )
                            }
                            <Grid item xs={12} >
                                <ItemCalendar item={item} summaryOpened={summaryOpened} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}