import { Box, Button, Grid, Typography } from "@material-ui/core";
import toast from "react-hot-toast";
import { Preorder, PreorderStatusStrings, PreorderStatuses } from "../../../../types/calendar.type";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { allowNft, isApproved } from "../../../../store/slices/nftSlice";
import config from "../../../../config";
import { Item } from "../../../../types/libofthings.type";
import { useContext, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { BlackButton } from "../../../../components/Buttons/BlackButton";
import { hoursToEndDate } from "../../../../utilities/timer/daysToEndDate";
import { ENDING_PREORDER_WARNING_HOURS } from "../../../../utilities/costants.calendar";
import { MailContext } from "../../../../providers/MailProvider";
import { FirstLifeContextImpl } from "../../../../providers/FirstLifeProvider";

const useStyles = makeStyles({
    // buttonContainer: {
    //     marginTop: '3em',
    //     ['@media (max-width:600px)']: {
    //         marginTop: '1em'
    //     }
    // }
})

export const ButtonsToHandleActivePreorders = ({ preorder, removeFromList, item, nftTemplate }: {
    preorder: Preorder,
    removeFromList?: (id: string, accepted: boolean) => void,
    item: Item,
    nftTemplate: string
}) => {
    const styles = useStyles();
    const dispatch = useAppDispatch();
    const loading = useAppSelector(state => state.nft.loading);
    const profile = useAppSelector(state => state.user.currentProfile)
    const [userDidApprove, setUserDidApprove] = useState<boolean>(false);
    const [hoursMissing, setHoursMissing] = useState(0);
    const { sendEmailWrapper } = useContext(MailContext);
    const { getUserByAddress } = useContext(FirstLifeContextImpl);

    useEffect(() => {
        checkIfAddressIsApproved();
        setHoursMissing(hoursToEndDate(preorder.endDate));
    }, []);

    const handleApproveNftToEndLendingPeriod = async () => {
        try {
            await dispatch(allowNft(nftTemplate, config.smartContracts.CALENDAR_ADDRESS, item.idInCollection))
            const { email } = await getUserByAddress(preorder.lender);
            const loggedUserInfo = await getUserByAddress(profile?.additional_properties?.commonshoodWallet!);

            await sendEmailWrapper(
                email,
                'Ci sono delle novità dalla Biblioteca delle cose di COSO',
                `
                ${loggedUserInfo.first_name} ti ha inviato una richiesta di termine prestito
                <br/><br/>
                Accedi alla <a href="https://coso-beta.commonshood.eu/">Biblioteca delle cose</a>:
                <br/>
                <ul>
                    <li>Trovi la richiesta nella sezione <b>"Metto in prestito - Attive"</b></li>
                    <li>Fai click sul pulsante <b>"Termina"</b> per confermare la tua disponibilità nel terminare il prestito</li>
                </ul>   
                <br/>
                <b>Importante!</b> ${loggedUserInfo.first_name} ha richiesto il termine prestito e attende di essere contattato/a da te: scrivi al suo indirizzo email ${loggedUserInfo.email}, e organizzate
                un incontro per la consegna dell'oggetto!
                <br/><br/>
                <a href="https://www.progettocoso.org/">https://www.progettocoso.org/</a>
                <br/>
                <br/>
                <img src="https://resources-beta.commonshood.eu/ipfs/QmVd8aDiavT16ufhTGjSTPKbfMGrJSJdRR92Cqx4wjxgc9" alt="COSO" width="100" />
                `
            );
            setUserDidApprove(true);
            toast.success('Approvato');
        } catch (e: any) {
            console.log(e);
            toast.error(e.message)
        }
    }

    const checkIfAddressIsApproved = async () => {
        (async () => {
            const currentAddressApprovedEndOfPreorder = await dispatch(isApproved(nftTemplate, config.smartContracts.CALENDAR_ADDRESS, item.idInCollection))
            if (currentAddressApprovedEndOfPreorder != null) {
                setUserDidApprove(currentAddressApprovedEndOfPreorder);
            }
        })();
    }

    return (
        <Grid container direction="row">
            <Grid item xs={12} style={{ textAlign: 'center', marginBottom: '16px' }}>
                {
                    (preorder.status.toUpperCase() === PreorderStatuses.Started.toUpperCase()) && (
                        <>
                            {
                                (!userDidApprove) ?
                                    <>
                                        <Box style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                            <img src="/svgs/warning.svg" style={{ marginLeft: '16px' }}></img>
                                            <Typography align="center" variant="body2" style={{ padding: '15px' }}>
                                                {hoursMissing > 0 && hoursMissing <= 24 ? "Il prestito termina oggi. Devi restituire l'oggetto!" : "Hai riconsegnato l’oggetto? Clicca sul tasto termina per concludere il prestito"}
                                            </Typography>
                                        </Box>
                                        <Box textAlign={'center'}>
                                            <BlackButton style={{
                                                width: '70%',
                                                color: 'white'
                                            }} text={'Termina'} onClick={handleApproveNftToEndLendingPeriod} disabled={userDidApprove || loading} />
                                        </Box>
                                    </> : (
                                        <>
                                            {(hoursMissing > 0 && hoursMissing <= ENDING_PREORDER_WARNING_HOURS) ? (
                                                <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '24px' }}>
                                                    <img src="/svgs/warning.svg" style={{ marginRight: '16px' }}></img>
                                                    <Typography variant="body2">In attesa della risposta del proprietario/a</Typography>
                                                </Box>
                                            ) : (
                                                <Typography variant="body2">In attesa della risposta del proprietario/a</Typography>
                                            )}
                                        </>
                                    )
                            }
                        </>
                    )
                }
            </Grid>
        </Grid>
    );
}