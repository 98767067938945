import { FormikProps } from "formik"
import { ItemInfoInForm, ItemInfoSavedInLS } from "../../../types/items.type"
import { Box, Button, FormControlLabel, Grid, MenuItem, Radio, RadioGroup, Select, TextField, Typography, makeStyles } from "@material-ui/core";
import { LibraryOfThingsContractContext } from "../../../providers/LibraryOfThingsContractProvider";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { RealmContext } from "../../../providers/RealmProvider";
import { getBase64ImageFromFile } from "../../../utilities/localstorage/getBase64Image";
import { LocalStorageContext } from "../../../providers/LocalStorageProvider";
import imageCompression from 'browser-image-compression';

const style = makeStyles({
    textField: {
        width: '100%',
        maxWidth: '300px',
        borderBottom: '1px',
        borderColor: 'black',
        borderRadius: '10px',
    },
    container: {
        left: '1em',
        position: 'relative',
        ['@media (min-width: 600px)']: {
            marginTop: '2.5em'
        }
    },
    select: {
        width: '100%',
        maxWidth: '300px',
        position: 'relative',
        background: 'rgba(248, 248, 248, 1)',
        borderBottom: '1px',
        borderColor: 'black',
        borderRadius: '10px',
    },
    radioGroup: {
        ['@media (min-width: 600px)']: {
            flexDirection: 'row',
        }
    },
    priceLabel: {
        position: 'relative',
    },
    publishButton: {
        borderRadius: '15px',
        width: '200px',
        color: 'white',
        background: 'black',
        '&:hover': {
            background: 'rgba(235, 176, 76, 1)',
            color: 'black'
        },
        '&:disabled': {
            background: 'rgba(129, 129, 129, 1)',
            color: 'black'
        },
        '&:focus': {
            background: 'rgba(218, 93, 62, 1)',
            color: 'black'
        },
        '@media (max-width: 480px)': {
            width: '150px'
        }
    },
    saveButton: {
        borderRadius: '15px',
        width: '200px',
        color: 'black',
        background: 'white',
        display: 'inline-block',
        marginRight: '10px',
        '&:hover': {
            background: 'rgba(235, 176, 76, 1)',
            color: 'black'
        },
        '&:disabled': {
            background: 'rgba(129, 129, 129, 1)',
            color: 'black'
        },
        '&:focus': {
            background: 'rgba(218, 93, 62, 1)',
            color: 'black'
        },
        '@media (max-width: 480px)': {
            width: '150px'
        }
    },
    cancelButton: {
        borderRadius: '15px',
        width: '100px',
        display: 'inline-block',
        borderColor: 'none',
        background: 'none',
        border: 'none',
        color: 'rgba(218, 93, 62, 1)',
        ['@media (max-width: 600px)']: {
            marginTop: '1em'
        },
    },
    buttonContainer: {
        ['@media (max-width: 600px)']: {
            textAlign: 'center'
        }
    }
})

export const UploadItemRightPanel = ({ formik }: {
    formik: FormikProps<ItemInfoInForm>
}) => {
    const classes = style();
    const { loading } = useContext(LibraryOfThingsContractContext);
    const { isCurrentRealmCOSO, isCurrentRealmChGarden } = useContext(RealmContext);
    const { updateLocalStorageItems } = useContext(LocalStorageContext);
    const { t } = useTranslation('LibOfThings');

    const handleChangeTextField = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target != null && event.target.value != null) {
            const file = event.target.value;
            formik.setFieldValue('name', file);
        }
    }
    const handleChangeCategory = (event: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
    }>) => {
        if (event.target != null && event.target.value != null) {
            formik.setFieldValue('category', event.target.value);
        }
    }

    const saveItemInLocalStorage = async () => {
        let itemsInLocalStorage = localStorage.getItem('savedItems');
        let items: ItemInfoSavedInLS[] | undefined;

        if (itemsInLocalStorage == undefined)
            items = [];
        else
            items = JSON.parse(itemsInLocalStorage);

        let imageBase64;
        if (formik.values.image) {
            const compressedFile = await compressImageFile(formik.values.image);
            imageBase64 = await getBase64ImageFromFile(compressedFile);
        }

        const newItemToSaveInLS = {
            name: formik.values.name,
            category: formik.values.category,
            requireSupervision: formik.values.requireSupervision,
            imageBase64: formik.values.image ? imageBase64 : '',
            mime: formik.values.image?.type
        }

        updateLocalStorageItems('savedItems', JSON.stringify([...items!, newItemToSaveInLS]))
        formik.resetForm();
    }

    const compressImageFile = async (file: File): Promise<File> =>  {
        const options = {
            maxSizeMB: 2,
            maxWidthOrHeight: 1920,
            useWebWorker: true
        };

        try {
            const compressedFile = await imageCompression(file, options);
            return compressedFile;
        } catch (error) {
            console.error('Error during image compression:', error);
            return file;
        }
    };

    return (
        <Grid container className={classes.container} spacing={2}>
            <Grid item xs={12}>
                <TextField
                    value={formik.values.name}
                    id="standard-basic"
                    label={`${t('insertItemName')}*`}
                    onChange={handleChangeTextField}
                    className={classes.textField}
                    variant="outlined"
                />
                {formik.errors.name && formik.touched.name ? (
                    <div>{formik.errors.name}</div>
                ) : null}
            </Grid>
            <Grid item xs={12}>
                {
                    isCurrentRealmCOSO && (
                        <Select
                            variant="outlined"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            className={classes.select}
                            value={formik.values.category}
                            label="Categoria"
                            onChange={handleChangeCategory}
                        >
                            <MenuItem value={'GIOCHI'}>Giochi</MenuItem>
                            <MenuItem value={'ELETTRODOMESTICI/CUCINA'}>Elettrodomestici / Cucina</MenuItem>
                            <MenuItem value={'ARREDI'}>Arredi</MenuItem>
                            <MenuItem value={'TRASPORTO'}>Trasporto</MenuItem>
                            <MenuItem value={'ARTE MUSICA CULTURA'}>Arte musica cultura</MenuItem>
                            <MenuItem value={'FAI DA TE'}>Fai da te</MenuItem>
                            <MenuItem value={'ABBIGLIAMENTO'}>Abbigliamento</MenuItem>
                            <MenuItem value={'PIANTE'}>Piante</MenuItem>
                            <MenuItem value={'ARTIGIANATO'}>Artigianato</MenuItem>
                            <MenuItem value={'ALTRO'}>Altro</MenuItem>
                        </Select>
                    )
                }
                {
                    isCurrentRealmChGarden && (
                        <Select
                            variant="outlined"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            className={classes.select}
                            label="Categoria"
                            value={formik.values.category}
                            onChange={handleChangeCategory}
                        >
                            <MenuItem value={'Giardinaggio'}>Giardinaggio</MenuItem>
                        </Select>
                    )
                }
            </Grid>
            <Grid item xs={12} style={{ marginBottom: '16px' }}>
                <Typography variant="body1">Indica se l'oggetto va utilizzato con la tua supervisione o in autonomia:</Typography>
                <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    onChange={(e) => {
                        formik.setFieldValue('requireSupervision', e.target.value === 'true');
                    }}
                >
                    <FormControlLabel value="false" control={<Radio />} label="in autonomia" />
                    <FormControlLabel value="true" control={<Radio />} label="con la mia supervisione" />
                </RadioGroup>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: '3em', marginTop: '1em' }}>
                <Grid container className={classes.buttonContainer}>
                    <Grid item xs={11} sm={12} >
                        <Button
                            variant="outlined"
                            disabled={loading}
                            onClick={saveItemInLocalStorage}
                            className={classes.saveButton}>
                            Salva bozza
                        </Button>
                        <Button
                            type="submit"
                            variant="outlined"
                            disabled={loading}
                            className={classes.publishButton}>
                            Pubblica
                        </Button>
                    </Grid>
                    <Grid item xs={11} sm={8}>
                        <Box
                            style={{
                                marginTop: '10px',
                                textAlign: 'center'
                            }}
                        >
                            <Button
                                variant="outlined"
                                disabled={loading}
                                className={classes.cancelButton}>
                                Annulla
                            </Button>
                        </Box>

                    </Grid>
                </Grid>
            </Grid>
        </Grid>

    );
}